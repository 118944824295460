import first from "./16aa1a32374d1395db752d73c37b1bba";
import second from "./d54c47419c2bfec284513356c285816a";
import third from "./1c8b84aacc5f5e81cadf8508cf8ddd6c";

const labels = {
  "16aa1a32374d1395db752d73c37b1bba": first,
  d54c47419c2bfec284513356c285816a: second,
  "1c8b84aacc5f5e81cadf8508cf8ddd6c": third,
};

export default labels[process.env.REACT_APP_LABEL_STYLE];
