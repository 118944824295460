import moment from "moment";

export function systemsWithDataArr(systems, systemStatus, fillSubsystems) {
  return systems.length
    ? systems.filter((system) => {
        if (!system.parent_id) {
          const systemSeverity = systemStatus[system.id]
            ? +systemStatus[system.id].severity
            : 0;
          const systemHealth = systemStatus[system.id]
            ? +systemStatus[system.id].syshealthpct
            : 0;
          return !(systemHealth === 0 && systemSeverity === 0);
        } else {
          fillSubsystems({ ...system });
          return false;
        }
      })
    : [];
}

export function setEmptySystems(systems, systemStatus) {
  const emptySystems = systems.length
    ? systems.filter((system) => {
        const systemSeverity = systemStatus[system.id]
          ? +systemStatus[system.id].severity
          : 0;
        const systemHealth = systemStatus[system.id]
          ? +systemStatus[system.id].syshealthpct
          : 0;
        return systemHealth === 0 && systemSeverity === 0 && !system.parent_id;
      })
    : [];
  return emptySystems;
}

export function sortedSubsystemsArr(system, subsystems) {
  return [...subsystems[system.parent_id], system].sort(
    (system, nextSystem) => {
      const systemSeverity = system.status ? +system.status.severity : 0;
      const nextSystemSeverity = nextSystem.status
        ? +nextSystem.status.severity
        : 0;
      const systemHealth = system.status ? +system.status.syshealthpct : 0;
      const nextSystemHealth = nextSystem.status
        ? +nextSystem.status.syshealthpct
        : 0;
      // sorting logic
      if (
        systemSeverity === nextSystemSeverity &&
        systemHealth === nextSystemHealth
      ) {
        return 0;
      } else {
        return (
          nextSystemSeverity - systemSeverity || systemHealth - nextSystemHealth
        );
      }
    }
  );
}

export function sortedNotEmptySystemsBySeverityAndEPI(
  systemsWithData,
  systemStatus
) {
  const sortedSystems = systemsWithData.length
    ? systemsWithData.sort((system, nextSystem) => {
        let systemSeverity = 0;
        let nextSystemSeverity = 0;
        let systemHealth = 0;
        let nextSystemHealth = 0;
        systemSeverity = systemStatus[system.id]
          ? +systemStatus[system.id].severity
          : 0;
        nextSystemSeverity = systemStatus[nextSystem.id]
          ? +systemStatus[nextSystem.id].severity
          : 0;
        systemHealth = systemStatus[system.id]
          ? +systemStatus[system.id].syshealthpct
          : 0;
        nextSystemHealth = systemStatus[nextSystem.id]
          ? +systemStatus[nextSystem.id].syshealthpct
          : 0;
        // sorting logic
        if (
          systemSeverity === nextSystemSeverity &&
          systemHealth === nextSystemHealth
        ) {
          return 0;
        } else {
          return (
            nextSystemSeverity - systemSeverity ||
            systemHealth - nextSystemHealth
          );
        }
      })
    : [];
  return sortedSystems.map((system, index) => ({
    ...system,
    isOpenedCard: index === 0,
  }));
}

export function monthsDataToRenderArr(payload, props) {
  return payload.existingMonthsArray.map((elem, index) => {
    let monthName = props.t(moment(elem.month, "MM").format("MMMM"));
    let messages = props.sysMessages.filter((msg) => {
      const dateSplit = msg.date.split(".");
      return +dateSplit[1] === elem.month && +dateSplit[2] === elem.year;
    });
    const monthDataFieldsArray = payload.monthlyDataArray.filter(
      (dataField) =>
        dataField.month === elem.month && dataField.year === elem.year
    );

    // organized as an array instead of an object, replaced, for now commented out
    // const values = monthDataFieldsArray.map((value) => {
    //   let outputValue =
    //     value.value === "0" ? value.value : value.value ? +value.value : "";
    //   return { fieldName: value.fieldName, value: outputValue };
    // });

    const values = monthDataFieldsArray.reduce((acc, value) => {
      const parsedValue =
        value.value === "0" ? value.value : value.value ? +value.value : "";
      acc[value.fieldName] = parsedValue;
      return acc;
    }, {});

    return {
      month: elem.month,
      year: elem.year,
      values,
      monthName,
      messages,
      index,
    };
  });
}

export function handleBarWidth(value, defaultValue = 0) {
  const valueNumber = Number.parseFloat(value);
  if (Number.isNaN(valueNumber)) {
    return defaultValue;
  }
  return valueNumber;
}
