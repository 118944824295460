const sendForm = (data, subject, token, sendTo = "") => {
  // FormData doesn't play nice with backend, so string is built here
  // const formData = new FormData();
  // Object.keys(data)
  //   .filter((val) => !val.startsWith("error"))
  //   .forEach((key) => formData.append(key, data[key]));

  let formString = Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith("error")) {
      acc += `${key}: '${data[key]}'\n`;
    }
    return acc;
  }, "");

  if (!formString) {
    formString = "empty form";
  }

  const url = `${process.env.REACT_APP_AWS_API_URL}/send-form-email`;
  return fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      label: sendTo || process.env.REACT_APP_LABEL_NAME.toLowerCase(),
      subject: subject,
      message: formString,
    }),
  });
};

export default sendForm;
