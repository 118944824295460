import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 10px;
  background-color: #aaa;
  position: relative;
  color: black;
  border-radius: 2em;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
`;
const Positive = styled.div`
  position: absolute;
  height: 100%;
  background-color: green;
  color: green;
  max-width: 50%;
  left: 50%;
  top: 0;
  border-left: 1px solid black;
`;
const Negative = styled.div`
  position: absolute;
  height: 100%;
  background-color: #e5b956;
  max-width: 50%;
  color: red;
  right: 50%;
  top: 0;
  border-right: 1px solid black;
`;

// const ResContainer = styled.span`
//   position: absolute;
//   padding: 0 8px;
//   bottom: 10px;
// `

const DoubleBar = (props) => {
  const pct = Math.abs(props.val / props.max);
  // const resStyle = props.val > 0 ? {
  //   left: '50%',
  // } : {
  //   right: '50%',
  // }

  return (
    <Wrap dir="ltr">
      {props.val > 0 ? (
        <Positive
          style={{
            width: pct * 100 + "%",
            borderRadius: pct * 100 >= 50 ? "0em 2em 2em 0em" : "none",
          }}
        />
      ) : (
        <Negative
          style={{
            width: pct * 100 + "%",
            borderRadius: pct * 100 >= 50 ? "2em 0em 0em 2em" : "none",
          }}
        />
      )}
    </Wrap>
  );
};

DoubleBar.propTypes = {
  max: PropTypes.number,
  val: PropTypes.number,
};
DoubleBar.defaultProps = {
  max: 100,
  val: 37,
};

export default DoubleBar;
