import * as React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import moment from "moment";
// import firebase from '../firebase';
import {
  loadDailyInsightsFromAws,
  lazyLoadMonthlyInsightsFromAws,
  loadAllAnnualInsightsFromAws,
  loadMessagesFromAws,
} from "../services/data";

import Layout from "../components/DefaultLayout";
import OrgSystemsList from "../components/OrgSystemsList";
import LoadingAnim from "../components/LoadingAnim/ThreeDots";
import MsgContainer from "../components/MsgContainer";
import UserRoles from "../services/UserRoles";

import Header from "../components/Header";

class DashboardPage extends React.Component {
  static propTypes = {
    userPhone: PropTypes.string,
    user: PropTypes.object,
    userExtra: PropTypes.object,
  };
  static defaultProps = {
    userPhone: null,
    user: null,
    userExtra: null,
  };

  // handle to polling timeout
  timer;

  constructor(props) {
    super(props);

    const role = UserRoles[this.props.userExtra.role]
      ? UserRoles[this.props.userExtra.role]
      : UserRoles.demo;
    this.state = {
      orgLoading: false,
      org: null,
      orgError: null,
      sysStatus: null,
      sysStatusAnnual: {},
      statusLoading: false,
      sysStatusMonthly: null,
      statusError: null,
      yearMessages: null,
      role,
      fetchingFromAws: {},
      lastSysUpdated: {},
      lastOrgUpdated: "",
      reloadingAllSystems: false,
      systemWideDate: moment(),
      dateFormat: "DD/MM/YYYY",
    };

    this.loadStatus = this.loadStatus.bind(this);
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview("/dashboard");
    }
    const orgData = this.props.orgData;
    if (!orgData) {
      this.setState({ orgError: true });
      return;
    }
    // alert if a system is missing, it's here to avoid hoisting translation function
    if (orgData.systemIds.length - orgData.systems.length !== 0) {
      window.alert(this.props.t("Missing system"));
    }
    const sysIds = orgData.systems.map((sys) => sys.id);
    this.loadStatus(sysIds);
  }

  loadAllDailyInsights = (date) => {
    const sysIds = this.props.orgData.systems.map((sys) => sys.id);
    // abort if any system is already fetching
    if (
      this.state.reloadingAllSystems ||
      sysIds.some((id) => this.state.fetchingFromAws[id])
    ) {
      return;
    }
    this.setState({ reloadingAllSystems: true }, () => {
      this.props.userData.getIdToken(false).then((token) => {
        loadDailyInsightsFromAws(token, sysIds, date)
          .then((data) => {
            this.setState((state) => {
              const sysStatus = Object.assign({ ...state.sysStatus }, data);
              return {
                sysStatus,
                reloadingAllSystems: false,
                statusLoading: false,
                statusError: null,
              };
            });
          })
          .catch((err) => {
            console.error("Error loading from sheets", err);
            this.setState({
              sysStatus: null,
              fetchingFromAws: {},
              reloadingAllSystems: false,
              statusLoading: false,
              statusError: err,
            });
          });
      });
    });
  };

  changeSystemWideDate = (newDate, ...momentOpts) => {
    const newMmnt = moment(newDate, ...momentOpts);
    if (newMmnt.isValid()) {
      this.setState({ systemWideDate: newMmnt }, () =>
        this.loadAllDailyInsights(newMmnt)
      );
    }
  };

  loadMonthlyInsights = (sysId, date) => {
    this.props.userData
      .getIdToken(false)
      .then((token) =>
        lazyLoadMonthlyInsightsFromAws(
          token,
          [sysId],
          [date.format("YYYY-MM-DD")]
        )
      )
      .then(({ data }) =>
        this.setState((state) => {
          const sysStatusMonthly = { ...state.sysStatusMonthly };
          Object.keys(data).forEach((sysId) => {
            sysStatusMonthly[sysId] = {
              ...data[sysId],
              loadedMonth: moment(date),
            };
          });
          return { sysStatusMonthly };
        })
      );
  };

  // it is long, but it tries to ensure no more than a single fetch per system is happening at once
  // given react's asyncronous state management nature
  loadDailyInsights = (sysId, date) => {
    this.setState(
      (state) => {
        // ensuring no current fetch is happening for this system
        const fetchingFromAws = { ...state.fetchingFromAws };
        if (fetchingFromAws[sysId] || state.reloadingAllSystems) {
          return;
        }
        // signal that on next callback fetch can start
        fetchingFromAws[sysId] = "pending";
        return { fetchingFromAws };
      },
      () => {
        // only continue callback if pending fetch from previous setState
        if (this.state.fetchingFromAws[sysId] !== "pending") {
          return;
        }
        this.setState(
          (state) => {
            // signal that fetch is about to start, locking the system for further operations
            const fetchingFromAws = { ...state.fetchingFromAws };
            fetchingFromAws[sysId] = "fetching";
            return { fetchingFromAws };
          },
          () => {
            // actual fetch starting
            this.props.userData.getIdToken(false).then((token) => {
              loadDailyInsightsFromAws(token, [sysId], date)
                .then((data) => {
                  this.setState((state) => {
                    const fetchingFromAws = { ...state.fetchingFromAws };
                    // opening the system for further fetch operations
                    delete fetchingFromAws[sysId];
                    const sysStatus = Object.assign(
                      { ...state.sysStatus },
                      data
                    );
                    return {
                      sysStatus,
                      fetchingFromAws,
                      statusLoading: false,
                      statusError: null,
                    };
                  });
                })
                .catch((err) => {
                  console.error("Error loading from sheets", err);
                  this.setState({
                    sysStatus: null,
                    fetchingFromAws: {},
                    statusLoading: false,
                    statusError: err,
                  });
                });
            });
          }
        );
      }
    );
  };

  // load system status
  // loadStatus(orgData) {
  //   const sysIds = orgData.systems.map(sys => sys.id);

  loadStatus(sysIds) {
    this.setState({
      statusLoading: true,
      // orgLoading: true,
    });
    // added load from aws after token is accepted
    this.props.userData.getIdToken(false).then((token) => {
      let initialDate = moment();
      loadDailyInsightsFromAws(token, sysIds, initialDate)
        .then((data) => {
          // if day is empty, fetch yesterday
          if (Object.values(data).every((result) => !result.severity)) {
            initialDate = initialDate.subtract(1, "day");
            data = loadDailyInsightsFromAws(token, sysIds, initialDate);
          }
          return Promise.all([data, initialDate]);
        })
        .then(([data, dataDate]) => {
          const { dateFormat } = this.state;
          const lastUpdates = Object.fromEntries(
            Object.entries(data).map(([sysId, sysData]) => {
              return [sysId, sysData.lastUpdated];
            })
          );
          this.setState(() => {
            const lastSysUpdated = Object.keys(data).map((sysId) => {
              if (lastUpdates[sysId]) {
                return [sysId, lastUpdates[sysId].format(dateFormat)];
              }
              const sysLastUpdate = data[sysId].lastUpdated; // added for older browser support
              return [
                sysId,
                sysLastUpdate ? sysLastUpdate.format(dateFormat) : "",
              ];
            });
            const lastOrgUpdated = lastSysUpdated.reduce(
              (orgUpdate, [sysId, sysUpdate]) => {
                const currentUpdated = moment(sysUpdate, dateFormat);
                if (currentUpdated.isValid()) {
                  if (!orgUpdate) {
                    orgUpdate = currentUpdated;
                  } else if (orgUpdate.isBefore(currentUpdated, "day")) {
                    orgUpdate = moment(sysUpdate, dateFormat);
                  }
                }
                return orgUpdate;
              },
              null
            );
            return {
              sysStatus: data,
              statusLoading: false,
              statusError: null,
              systemWideDate: moment(dataDate),
              lastSysUpdated: Object.fromEntries(lastSysUpdated),
              lastOrgUpdated:
                lastOrgUpdated !== null
                  ? lastOrgUpdated.format(dateFormat)
                  : "",
            };
          });
        })
        .catch((err) => {
          console.error("Error loading from sheets", err);
          this.setState({
            sysStatus: null,
            statusLoading: false,
            statusError: err,
          });
        });
    });

    const initialMonthDate = moment().startOf("month");

    this.props.userData
      .getIdToken(false)
      .then((token) =>
        Promise.all([
          lazyLoadMonthlyInsightsFromAws(token, sysIds, [
            initialMonthDate.format("YYYY-MM-DD"),
          ]),
          loadMessagesFromAws(token, sysIds, moment()),
        ])
      )
      .then(([{ data: monthlyData }, messagesData]) => {
        Object.keys(monthlyData).forEach(
          (sysId) => (monthlyData[sysId].loadedMonth = moment(initialMonthDate))
        );
        this.setState({
          sysStatusMonthly: monthlyData,
          yearMessages: messagesData,
        });
      })
      .catch((err) => {
        console.error("Error loading from monthly sheets", err);
        this.setState({
          sysStatusMonthly: null,
        });
      });

    this.props.userData
      .getIdToken(false)
      .then((token) => loadAllAnnualInsightsFromAws(token, sysIds))
      .then((data) => {
        this.setState({
          sysStatusAnnual: data,
        });
      })
      .catch((err) => {
        console.error("Error loading from annual sheets", err);
        this.setState({
          sysStatusAnnual: {},
        });
      });
  }

  // returns current user token, sent down the dom for lower nodes
  getUserToken = async () => {
    return await this.props.userData.getIdToken(false);
  };

  renderHeader() {
    return (
      <Header
        text="Dashboard"
        userName={this.props.userExtra.name}
        orgName={this.props.orgData ? this.props.orgData.name : "NA"}
        orgIsEnabled={this.props.orgData ? this.props.orgData.isEnabled : false}
      />
    );
  }

  renderContent = () => {
    const {
      orgError,
      statusError,
      sysStatus,
      sysStatusAnnual,
      sysStatusMonthly,
      yearMessages,
    } = this.state;
    const { orgData, isFetching } = this.props;
    if (isFetching) {
      return (
        <div>
          <LoadingAnim>Loading...</LoadingAnim>
        </div>
      );
    }
    // org error exists, show error
    if (orgError !== null) {
      return <MsgContainer text="No organization data" />;
    }
    // no org, show error
    if (!orgData) {
      return <MsgContainer text="No organization data" />;
    }
    // no systems show error
    if (!Array.isArray(orgData.systems) || !orgData.systems.length) {
      return <MsgContainer text="No systems found for organization" />;
    }
    // if (statusLoading) {
    //   return (
    //     <div>
    //       <LoadingAnim>
    //         Loading Status...
    //       </LoadingAnim>
    //     </div>
    //   )
    // }
    if (statusError !== null) {
      return (
        <MsgContainer text="Error loading system status data, please refresh" />
      );
    }
    // const t = this.props.t;
    if (!sysStatus) {
      return <LoadingAnim />;
    }
    if (!sysStatusMonthly) return <LoadingAnim />;
    if (!sysStatusAnnual) return <LoadingAnim />;

    if (sysStatus && sysStatusMonthly && sysStatusAnnual) {
      return (
        <OrgSystemsList
          orgData={this.props.orgData}
          sysStatus={this.state.sysStatus}
          sysStatusAnnual={this.state.sysStatusAnnual}
          sysStatusMonthly={this.state.sysStatusMonthly}
          isFetching={this.state.statusLoading}
          loadStatuses={() =>
            this.loadStatus(this.props.orgData.systems.map((sys) => sys.id))
          }
          yearMessages={yearMessages}
          userRole={this.state.role}
          loadDailyInsights={this.loadDailyInsights}
          loadMonthlyInsights={this.loadMonthlyInsights}
          lastSysUpdated={this.state.lastSysUpdated}
          lastOrgUpdated={this.state.lastOrgUpdated}
          getIdToken={this.getUserToken}
          userName={this.props.userExtra.name}
          changeSystemWideDate={this.changeSystemWideDate}
          reloadingAllSystems={this.state.reloadingAllSystems}
          systemWideDate={this.state.systemWideDate}
          dateFormat={this.state.dateFormat}
          preferences={this.props.preferences}
        />
      );
    }
  };

  render() {
    return <Layout header={this.renderHeader()} body={this.renderContent()} />;
  }
}

DashboardPage.propTypes = {
  userData: PropTypes.object,
  userExtra: PropTypes.object,
  parentSystemIds: PropTypes.array,
  orgData: PropTypes.object,
};

export default withTranslation()(DashboardPage);
