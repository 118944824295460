import * as React from "react";
import { withTranslation } from "react-i18next";
import { Chart } from "react-charts";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";

const ChartContainer = styled.div`
  height: 30rem;
  direction: ltr;
  margin-bottom: 5px;
`;

const getAxesDirections = (primaryIsOnLeft) => {
  if (primaryIsOnLeft) {
    return {
      primaryAxisDirection: "left",
      primaryAxisInvert: false,
      secondaryAxisDirection: "bottom",
      secondaryAxisInvert: false,
    };
  }
  return {
    primaryAxisDirection: "bottom",
    primaryAxisInvert: false,
    secondaryAxisDirection: "left",
    secondaryAxisInvert: false,
  };
};

function PowerChart(props) {
  const { opened, dataPoints, primaryAxisIsOnLeft, t } = props;
  const {
    primaryAxisDirection,
    secondaryAxisDirection,
    primaryAxisInvert,
    secondaryAxisInvert,
  } = getAxesDirections(primaryAxisIsOnLeft);

  const data = React.useMemo(
    () => [
      {
        label: "Power [kW]",
        data: dataPoints || [],
      },
    ],
    [dataPoints]
  );

  // the type of the chart
  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );

  // axes defenition of the chart
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "ordinal",
        position: primaryAxisDirection,
        invert: primaryAxisInvert,
        format: (hour) => `${+hour}:00`,
      },
      {
        position: secondaryAxisDirection,
        type: "linear",
        invert: secondaryAxisInvert,
      },
    ],
    [
      primaryAxisDirection,
      secondaryAxisDirection,
      primaryAxisInvert,
      secondaryAxisInvert,
    ]
  );

  return (
    <Collapse in={opened}>
      {!dataPoints ? (
        <div>{t("No Data")}</div>
      ) : (
        <ChartContainer>
          <Chart data={data} series={series} axes={axes} />
        </ChartContainer>
      )}
    </Collapse>
  );
}

export default withTranslation()(PowerChart);
