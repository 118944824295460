// allowed value for a setting, for ui elements to render
export default class AllowedValue {
  name;
  value;
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }

  copy() {
    return new AllowedValue(this.name, this.value);
  }
}
