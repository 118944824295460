import moment from "moment";

const DATE_FORMAT = {
  sheet: "DD/MM/YYYY",
  db: "YYYY-MM-DD",
};

// if null/undefined are passed returns an empty string
const createString = (toString) => {
  if (toString !== 0 && !toString) {
    return "";
  }
  return `${toString}`;
};

const createInvDataObjFromDb = (originalInvDataArr, basePropName) => {
  const invDataArr = Array.isArray(originalInvDataArr)
    ? originalInvDataArr
    : [];

  return Array(invDataArr.length)
    .fill(0)
    .reduce((acc, curr, idx) => {
      acc[`${basePropName}${idx + 1}`] = invDataArr[idx]
        ? invDataArr[idx]
        : "nodata";
      return acc;
    }, {});
};

function parseSysPower(sysPower) {
  if (!Array.isArray(sysPower) || !sysPower.length) {
    return null;
  }
  const result = sysPower.map((value, hour) => {
    return [+hour, !value ? 0 : +value];
  });
  return result.length ? result : null;
}

function dbToAppModel(sysId, res) {
  if (res && sysId !== res.systemId) {
    throw new Error(
      `wrong system pulled, expected'${sysId}' got '${res.systemId}'`
    );
  }
  if (!res) {
    res = {};
  }
  const date = moment(res.date, DATE_FORMAT.db);

  this.daily_sys_power = parseSysPower(res.daily_sys_power);

  this.dailyuptime = createString(res.dailyuptime);
  this.daily_exp_uptime = createString(res.dailyexpuptime);
  this.daily_exp_uptime_ratio = createString(res.dailyexpuptimeratio);

  this.date = date.isValid() ? date.format(DATE_FORMAT.sheet) : "";
  this.severity = createString(res.severity);
  this.regionrating = createString(res.region_rating);
  this.syshealthpct = createString(res.syshealthpct);
  this.drevenue = createString(res.drevenue);
  this.drevloss = createString(res.drevloss);
  this.denoutput = createString(res.denoutput);
  this.depotential = createString(res.depotential);
  this.expdaily = createString(res.expdaily);
  this.dailyratio = createString(res.dailyratio);
  this.yearloss = createString(res.yearloss);
  this.yepotential = createString(res.yepotential);
  this["ef-message"] = createString(res.ef_message); // NOT an enum, free form string
  this["ef-webbox"] = createString(res.ef_logger); // NOT an enum, free form string
  this["f-comm"] = createString(res.f_comm); // enum
  this["f-erange"] = createString(res.f_erange); // enum
  this["f-prange"] = createString(res.f_prange); // enum
  this["f-vgrid"] = createString(res.f_vgrid); // enum
  this["f-vgridshift"] = createString(res.f_vgridshift); // enum
  this["f-dust"] = createString(res.f_dust); // enum
  this["f-mcm"] = createString(res.f_mcm); // enum
  this["f-fct"] = createString(res.f_fct); // enum
  this["f-ovld"] = createString(res.f_ovld); // enum
  this["f-intres"] = createString(res.f_intres); // enum
  this["f-shadow"] = createString(res.f_shadow); // enum
  Object.assign(this, createInvDataObjFromDb(res.if_pow, "if-pow-"));
  Object.assign(
    this,
    createInvDataObjFromDb(res.if_latestart, "if-latestart-")
  );
  Object.assign(this, createInvDataObjFromDb(res.if_powdeg, "if-powdeg-"));
  Object.assign(this, createInvDataObjFromDb(res.if_inv_n, "if-inv-"));
  Object.assign(this, createInvDataObjFromDb(res.if_invdeg, "if-invdeg-"));

  this.emptyDay = false;
  this.lastUpdated = null;
}

const createSheetDataFromDbData = (sysIds, dbResponses, date) => {
  return sysIds.reduce((acc, sysId, idx) => {
    const res = Array.isArray(dbResponses[idx])
      ? dbResponses[idx][0] || null
      : null;
    acc[sysId] = { ...new dbToAppModel(sysId, res) };
    // if missing date or denoutput consider day as empty
    if (!acc[sysId].date || !acc[sysId].denoutput) {
      acc[sysId].date = moment(date).format(DATE_FORMAT.sheet);
      acc[sysId].emptyDay = true;
    }
    if (acc[sysId].date && (acc[sysId].denoutput || acc[sysId].severity)) {
      acc[sysId].lastUpdated = moment(acc[sysId].date, DATE_FORMAT.sheet);
    }
    return acc;
  }, {});
};

export default createSheetDataFromDbData;
