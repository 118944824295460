import * as React from "react";
import styled from "styled-components";
import { handleBarWidth } from "../../services/systemsArrayHelper";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 20px;
  background-color: #a1a3a5;
  position: relative;
  color: white;
  border-radius: 2em;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
`;
const Bar = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(
    to right,
    green ${(props) => props.percentage}%,
    red ${(props) => props.percentage}%
  );
  border-radius: 2em;
`;
const Total = styled.div`
  z-index: 10;
  position: absolute;
  left: 10px;
`;
const Units = styled.span`
  font-size: 75%;
`;

// const ResContainer = styled.span`
//   position: absolute;
//   padding: 0 8px;
//   bottom: 10px;
// `

const DoubleBar = (props) => {
  const { val, total, units } = props;
  const intact = val > 100 ? 100 : val;
  // const resStyle = props.val > 0 ? {
  //   left: '50%',
  // } : {
  //   right: '50%',
  // }

  return (
    <Wrap dir="ltr">
      <Bar percentage={handleBarWidth(intact, null)} />
      <Total>
        {Math.round(total)} <Units>{units}</Units>
      </Total>
    </Wrap>
  );
};

export default DoubleBar;
