import * as React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";

import Calendar from "../Calendar";

const DaySwitcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
`;
const DayName = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
`;

class SystemWideCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleShowModal = () => {
    if (this._isMounted) {
      this.setState({ isVisible: true });
    }
  };

  handleHideModal = () => {
    if (this._isMounted) {
      this.setState({ isVisible: false });
    }
  };

  handleNewModalDate = (newDate) => {
    if (this._isMounted) {
      this.props.onChangeSystemWideDate(newDate);
      this.setState({ isVisible: false });
    }
  };

  render() {
    const { t, i18n, currentDate, dateFormat } = this.props;
    const { isVisible } = this.state;
    const direction = i18n.dir();

    // set first day of week and weekends
    const calendarType = direction === "rtl" ? "Hebrew" : "ISO 8601";

    return (
      <>
        <DaySwitcher>
          <DayName onClick={this.handleShowModal}>
            {currentDate.format(dateFormat)}
          </DayName>
        </DaySwitcher>
        <Modal show={isVisible} onHide={this.handleHideModal} bsSize="small">
          <Modal.Body>
            <Calendar
              shouldRender={isVisible}
              maxDate={new Date()}
              value={currentDate.toDate()}
              onChange={(newDate) => this.handleNewModalDate(newDate)}
              calendarType={calendarType}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleHideModal}>{t("Cancel")}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(SystemWideCalendar);
