const messageStatuses = {
  open: { value: "open", render: true, shouldHide: false, textColor: "black" },
  closed: {
    value: "closed",
    render: true,
    shouldHide: false,
    textColor: "gray",
  },
  cancelled: {
    value: "cancelled",
    render: true,
    shouldHide: true,
    textColor: "gray",
  },
  hidden: {
    value: "hidden",
    render: false,
    shouldHide: true,
    textColor: "gray",
  },
};

export default messageStatuses;
