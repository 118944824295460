import moment from "moment";

import dailyModel from "../models/dailyDbModel";
import monthlyModel from "../models/monthlyDbModel";
import annualModel from "../models/annualDbModel";

const SHEETS_DATE_FORMAT = "DD.MM.YYYY";
const AWS_DATE_FORMAT = "YYYY-MM-DD";
const AWS_DATE_AND_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const AWS_API_URL = process.env.REACT_APP_AWS_API_URL;

const INSIGHT_TYPES = {
  daily: {
    id: "daily", // id for references
    timeframe: "day", // used for moment to determine start of time period
    path: `/insights/search-daily`, // path in api
    model: dailyModel, // function that models the results to the shape that the app expects to get
  },
  monthly: {
    id: "monthly",
    timeframe: "month",
    path: `/insights/search-monthly`,
    model: monthlyModel,
  },
  annual: {
    id: "annual",
    timeframe: "year",
    path: `/insights/search-annual`,
    model: annualModel,
  },
};

export async function loadUserData(token) {
  const response = await fetch(`${AWS_API_URL}/userData`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ label: process.env.REACT_APP_LABEL_NAME }),
  });
  if (!response.ok) {
    const code = response.status;
    const msg =
      code === 403 ? "Forbidden" : code === 404 ? "Not Found" : "Error";
    throw new Error(msg);
  }
  const userExtra = await response.json();
  return userExtra;
}

export async function loadOrgData(token) {
  try {
    const response = await fetch(`${AWS_API_URL}/orgData`, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`load org data with status: ${response.status}`);
    }
    const orgData = await response.json();
    if (!orgData) {
      throw new Error("no org data");
    }
    return orgData;
  } catch (err) {
    console.error(err);
    throw new Error("No organization data");
  }
}

export function loadSupportUrls(token, sysIds) {
  const url = `${AWS_API_URL}/systems-support-url`;
  return fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ sysIds }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error("urls not found");
      }
      return res.json();
    })
    .catch(() => {
      return {};
    });
}

export function loadFromFirebase(phoneNumber) {
  let userData;
  return loadUserData(phoneNumber)
    .then((_userData) => {
      if (!_userData) {
        console.log("Unknown user");
      }
      userData = _userData;
      return loadOrgData(_userData.organization);
    })
    .then((orgData) => {
      return Promise.resolve({
        user: userData,
        org: orgData,
      });
    });
}

export function loadDailyInsightsFromAws(token, sysIds, date) {
  const insightType = INSIGHT_TYPES.daily;
  const startingDate = moment(date).startOf(insightType.timeframe);
  const dateStr = startingDate.format(AWS_DATE_FORMAT);

  const baseUrl = `${AWS_API_URL}${insightType.path}`;
  const fetchObj = {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      sysIds: sysIds,
      dates: [dateStr],
    }),
  };
  return fetch(baseUrl, fetchObj)
    .then((res) => res.json())
    .then((results) => {
      const orderedResults = sysIds.map((sysId) => {
        return results.filter((res) => res.systemId === sysId);
      });
      return insightType.model(sysIds, orderedResults, startingDate);
    });
}

export async function loadAllMonthlyInsightsFromAws(token, sysIds) {
  return await loadAllInsightsFromAws(token, sysIds, INSIGHT_TYPES.monthly.id);
}

export async function lazyLoadMonthlyInsightsFromAws(token, sysIds, dates) {
  return await loadAllInsightsFromAws(
    token,
    sysIds,
    INSIGHT_TYPES.monthly.id,
    dates
  );
}

export async function loadAllAnnualInsightsFromAws(token, sysIds) {
  const annual = await loadAllInsightsFromAws(
    token,
    sysIds,
    INSIGHT_TYPES.annual.id
  );
  return normalizeAnnualStatusData(annual);
}

function loadAllInsightsFromAws(token, sysIds, type, dates = []) {
  const insightType = INSIGHT_TYPES[type];
  const baseUrl = `${AWS_API_URL}${insightType.path}`;
  const fetchObj = {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      sysIds: sysIds,
      dates,
    }),
  };
  return fetch(baseUrl, fetchObj)
    .then((res) => res.json())
    .then((results) => {
      const orderedResults = sysIds.map((sysId) => {
        return results.filter((res) => res.systemId === sysId);
      });
      return insightType.model(sysIds, orderedResults);
    });
}

/**
 * convert to array of objects with per-year data.
 * {"y2011-addition": "2326.0", "y2011-change-pct": "2.8", "y2011-expected": "80746.9", "y2011-total": "83073.0", ...} =>
 * [{year: "2011", addition: 2326, change-pct: 2.8, expected: 80746.9, total: 83073}, ...]
 *
 * @param {object} rawData
 */
function normalizeAnnualStatusDataSys(rawData) {
  const result = {};
  let minYear = Infinity;
  let maxYear = -Infinity;
  Object.keys(rawData).forEach((col) => {
    const year = Number.parseInt(col.substring(1, 5));
    if (year <= minYear) {
      minYear = year;
    }
    if (maxYear <= year) {
      maxYear = year;
    }

    if (!(year in result)) {
      result[year] = { year };
    }
    const key = col.substring(6).replace("-", "_");
    const value = Number.parseFloat(rawData[col]);
    result[year][key] = Number.isFinite(value) ? value : null;
  });
  if (!Number.isFinite(minYear) || !Number.isFinite(maxYear)) {
    return [];
  }
  const resultArray = [];
  for (let i = minYear; i <= maxYear; ++i) {
    if (!result[i]) {
      resultArray.push({
        year: i,
        change_pct: null,
        intact: null,
        total: null,
        uptime: null,
        uptime_ratio: null,
        yield_ratio: null,
      });
    } else {
      resultArray.push(result[i]);
    }
  }
  return resultArray;
}

function normalizeAnnualStatusData(rawData) {
  const result = {};
  Object.keys(rawData).forEach((sysId) => {
    const nData = normalizeAnnualStatusDataSys(rawData[sysId]);
    result[sysId] = nData;
  });
  return result;
}

// TODO: add lazy loading, and utilize dates
export function loadMessagesFromAws(token, sysIds /*, date */) {
  // const startMonth = moment(date).startOf('month').format(AWS_DATE_FORMAT);
  // const endMonth = moment(date).endOf('month').format(AWS_DATE_FORMAT);

  return fetch(`${AWS_API_URL}/message-and-response-logs`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      sysIds: sysIds,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      // sort results by dateTime, currently it's still a string
      response.sort(
        ({ message: { dateTime: t1 } }, { message: { dateTime: t2 } }) =>
          t1 < t2 ? -1 : 1
      );
      return response.reduce((data, { message: res, responses }) => {
        // if the response's message field is an object and not a string, extract it
        const finalResult = {};
        finalResult.message =
          res.message && typeof res.message === "object"
            ? res.message.body
            : res.message;
        // push only if message is non-empty
        if (finalResult.message) {
          finalResult.id = res.id;
          finalResult.date = moment
            .utc(res.dateTime)
            .format(SHEETS_DATE_FORMAT);
          finalResult.system = res.systemId;
          finalResult.status = res.status;
          // sort responses to ensure they always appear in the same order
          finalResult.responses = responses.sort(
            ({ id: id1 }, { id: id2 }) => id1 - id2
          );
          data[res.systemId].push(finalResult);
        }
        return data;
      }, Object.fromEntries(sysIds.map((sys) => [sys, []])));
    })
    .catch((err) => err);
}

export function postNewMessageToAws(token, date, systemId, message) {
  const messageToString = (message + "").trim();
  if (!message || !messageToString) {
    throw new Error("new message must be a non-empty string");
  }
  const url = `${AWS_API_URL}/message-response-migrate`;
  const fetchObj = {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      messageLog: {
        systemId: systemId,
        dateTime: date.format(AWS_DATE_FORMAT),
        message: message,
        status: "open",
      },
    }),
  };
  return fetch(url, fetchObj).then(async (response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const resObject = await response.json();
    resObject.responses = [];
    return resObject;
  });
}

export function postNewResponseToAws(
  token,
  ticketId,
  newResponse,
  authLevel = "",
  responder = ""
) {
  const url = `${AWS_API_URL}/message-response-logs`;
  const fetchObj = {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ticketId: ticketId,
      authLvl: authLevel,
      responseMessage: newResponse,
      responder: responder,
      timestamp: moment().format(`${AWS_DATE_AND_TIME_FORMAT}`),
    }),
  };
  return fetch(url, fetchObj).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
}

export function changeMessageStatusAws(token, ticketId, newStatus) {
  const url = `${AWS_API_URL}/message/status/${ticketId}`;
  const fetchObj = {
    method: "put",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: newStatus,
    }),
  };
  return fetch(url, fetchObj).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
}
