import * as React from "react";
import { withTranslation } from "react-i18next";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
// should always be imported after the original style
import "./index.css";

const FORMATTER_OPTS = {
  weekday: { weekday: "short" },
  month: { month: "long" },
  monthYear: {
    month: "long",
    year: "numeric",
  },
};

const DATE_LOCALES = {
  en: {
    weekday: new Intl.DateTimeFormat("en-US", FORMATTER_OPTS.weekday),
    month: new Intl.DateTimeFormat("en-US", FORMATTER_OPTS.month),
    monthYear: new Intl.DateTimeFormat("en-US", FORMATTER_OPTS.monthYear),
  },
  he: {
    weekday: new Intl.DateTimeFormat("he-IL", FORMATTER_OPTS.weekday),
    month: new Intl.DateTimeFormat("he-IL", FORMATTER_OPTS.month),
    monthYear: new Intl.DateTimeFormat("he-IL", FORMATTER_OPTS.monthYear),
  },
  de: {
    weekday: new Intl.DateTimeFormat("de-DE", FORMATTER_OPTS.weekday),
    month: new Intl.DateTimeFormat("de-DE", FORMATTER_OPTS.month),
    monthYear: new Intl.DateTimeFormat("de-DE", FORMATTER_OPTS.monthYear),
  },
};

function formatShortWeekday(lang, date) {
  const formatter = DATE_LOCALES[lang] || DATE_LOCALES.en;
  return formatter.weekday.format(date);
}

function formatMonth(lang, date) {
  const formatter = DATE_LOCALES[lang] || DATE_LOCALES.en;
  return formatter.month.format(date);
}

function formatMonthYear(lang, date) {
  const formatter = DATE_LOCALES[lang] || DATE_LOCALES.en;
  return formatter.monthYear.format(date);
}

function StyledCalendar(props) {
  if (!props.shouldRender) {
    return null;
  }

  const {
    i18n: { language },
    maxDate,
    value,
    onChange,
    calendarType,
  } = props;
  return (
    <Calendar
      className="react-calendar-additions"
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      calendarType={calendarType}
      formatShortWeekday={(locale, date) => formatShortWeekday(language, date)}
      formatMonth={(locale, date) => formatMonth(language, date)}
      formatMonthYear={(locale, date) => formatMonthYear(language, date)}
    />
  );
}

export default withTranslation()(StyledCalendar);
