import * as React from "react";
import { withTranslation } from "react-i18next";
import {
  Modal,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";
import { MdSettings } from "react-icons/md";

import LoadingAnim from "../LoadingAnim/ThreeDots";
import sendForm from "../../services/contact";

function FieldGroup({ id, label, help, children, vstate = null, ...props }) {
  return (
    <FormGroup controlId={id} validationState={vstate}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props}>{children}</FormControl>
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

function YearOptions(props) {
  const NUM_OF_OPTS = 4;
  const { startYear } = props;
  return new Array(NUM_OF_OPTS).fill(+startYear).map((year, idx) => {
    const currYear = year + idx;
    return (
      <option key={idx} value={currYear}>
        {currYear}
      </option>
    );
  });
}

// months id's and representative names
const MONTHS_ARR = [
  { id: "controlJanuary", name: "January" },
  { id: "controlFebruary", name: "February" },
  { id: "controlMarch", name: "March" },
  { id: "controlApril", name: "April" },
  { id: "controlMay", name: "May" },
  { id: "controlJune", name: "June" },
  { id: "controlJuly", name: "July" },
  { id: "controlAugust", name: "August" },
  { id: "controlSeptember", name: "September" },
  { id: "controlOctober", name: "October" },
  { id: "controlNovember", name: "November" },
  { id: "controlDecember", name: "December" },
];

class MonthlyTargetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCleanState();
  }

  // clean state, used for contructor, and handleExit methods
  getCleanState = () => {
    const months = MONTHS_ARR.reduce((acc, month) => {
      acc[month.id] = "";
      return acc;
    }, {});
    return {
      isVisible: false,
      isSubmitting: false,
      selectedYear: "",
      ...months,
    };
  };

  // handle showing of the modal
  handleShow = () => {
    this.setState((state, props) => {
      return {
        isVisible: true,
        selectedYear: `${props.year}`,
      };
    });
  };

  // handles hiding of the modal
  handleHide = () => {
    this.setState((state) => {
      if (!state.isSubmitting) {
        return { isVisible: false };
      }
    });
  };

  // input validation, only numeric values and empty string are accepted
  validateField = (monthId) => {
    const value = this.state[monthId];
    return value === ""
      ? null
      : Number.isNaN(Number(value))
      ? "error"
      : "success";
  };

  // handles change of the month fields
  handleChange = (ev) => {
    this.setState({ [ev.target.id]: ev.target.value });
  };

  // submits the request
  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      const monthlyTargets = MONTHS_ARR.reduce((acc, { id, name }) => {
        acc[name] = this.state[id];
        return acc;
      }, {});

      const { sysId, userName, getIdToken } = this.props;
      const year = this.state.selectedYear || this.props.year;

      const payload = {
        "system id": sysId,
        username: userName,
        year: year,
        ...monthlyTargets,
      };

      const mailSubject = `'${sysId}': new monthly targets for ${year}`;

      getIdToken(false)
        .then((token) => sendForm(payload, mailSubject, token, "soltell"))
        .catch((err) => console.error(err.message))
        .finally(() => {
          this.setState({ isSubmitting: false }, this.handleHide);
        });
    });
  };

  // handles exit cleanup
  handleExit = () => {
    this.setState({ ...this.getCleanState() });
  };

  render() {
    const { t, sysName, year, i18n } = this.props;
    const { isSubmitting, selectedYear } = this.state;

    return (
      <>
        <MdSettings style={{ cursor: "pointer" }} onClick={this.handleShow} />
        <Modal
          show={this.state.isVisible}
          dir={i18n.dir()}
          onHide={this.handleHide}
          onExit={this.handleExit}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${t(
              "Modify monthly targets"
            )} ${year} - ${sysName}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FieldGroup
                id="selectedYear"
                label={t("Year")}
                value={selectedYear}
                componentClass="select"
                onChange={this.handleChange}
              >
                <YearOptions startYear={year} />
              </FieldGroup>
              {MONTHS_ARR.map(({ id, name }) => {
                return (
                  <FieldGroup
                    key={id}
                    id={id}
                    type="text"
                    onChange={this.handleChange}
                    label={t(name)}
                    placeholder={t("kwH/month")}
                    vstate={this.validateField(id)}
                    disabled={isSubmitting}
                  />
                );
              })}
            </form>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitting ? <LoadingAnim /> : null}
            <Button disabled={isSubmitting} onClick={this.handleHide}>
              {t("Cancel")}
            </Button>
            <Button
              disabled={
                isSubmitting || MONTHS_ARR.every(({ id }) => !this.state[id])
              }
              onClick={this.handleSubmit}
              type="submit"
              bsStyle="primary"
            >
              {t("Submit")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(MonthlyTargetModal);
