import moment from "moment";

const DATE_FORMAT = {
  sheet: "YYYYMM-",
  db: "YYYY-MM-DD",
};

const propDict = {
  monthly_epi: "epi",
  monthly_cumyear: "cumyear",
  monthly_rating: "rating",
  monthly_yield: "yield",
  monthly_expratio: "expratio",
  monthly_expect: "expect",
  preyield: "preyield",
  monthly_uptime: "uptime",
  monthly_exp_uptime: "exp_uptime",
  monthly_exp_uptime_ratio: "exp_uptime_ratio",
};

// if null/undefined are passed returns an empty string
const createString = (toString) => {
  if (toString !== 0 && !toString) {
    return "";
  }
  return `${toString}`;
};

// create dated key per the sheets format
const createDatedKey = (dateStr, key) => {
  return `${dateStr}${key}`;
};

function dbToAppModel(sysId, response) {
  const result = {};
  let firstYear = moment();
  response.forEach((res) => {
    // sanity check, ensuring that correct data is presented
    if (res.systemId !== sysId) {
      throw new Error(
        "monthly system data fetched does not match requested system"
      );
    }
    const logDate = moment(res.month, DATE_FORMAT.db);
    const month = logDate.format(DATE_FORMAT.sheet);
    const monthNextYear = moment(logDate).add(1, "year");
    const monthNextYearKey = monthNextYear.format(DATE_FORMAT.sheet);
    if (logDate.isBefore(firstYear)) {
      firstYear = logDate;
    }
    Object.entries(res).forEach(([key, value]) => {
      if (propDict[key]) {
        result[createDatedKey(month, propDict[key])] = createString(value);
      }
      // add preyield, and only if it's not adding it to the next year
      if (
        key === "monthly_yield" &&
        monthNextYear.isSameOrBefore(moment(), "month")
      ) {
        result[createDatedKey(monthNextYearKey, propDict.preyield)] =
          createString(value);
      }
    });
  });
  return {
    result,
    firstYear: firstYear.year(),
  };
}

const createSheetDataFromDbData = (sysIds, dbResponses) => {
  return sysIds.reduce(
    (acc, sysId, idx) => {
      const { result, firstYear } = dbToAppModel(sysId, dbResponses[idx]);
      acc.data[sysId] = result;
      if (firstYear < acc.firstYear) {
        acc.firstYear = firstYear;
      }
      return acc;
    },
    { data: {}, firstYear: moment().year() }
  );
};

export default createSheetDataFromDbData;
