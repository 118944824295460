import * as React from "react";
import ReactGA from "react-ga";
import FirebaseUIAuth from "../components/FirebaseUIAuth";
import firebase from "../firebase";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;
const Logo = styled.img`
  height: 3.5em;
`;

const uiConfig = {
  // Called when the user has been successfully signed in.
  callbacks: {
    signInSuccess: (currentUser, credential, redirectUrl) => {
      // Do not redirect.
      return false;
    },
  },
  // Opens IDP Providers sign-in flow in a popup.
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: "image", // 'audio'
        size: "normal", // 'invisible' or 'compact'
        badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
      },
      defaultCountry: "IL", // Set default country to the United Kingdom (+44).
    },
    // {
    //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //   scopes: ['https://www.googleapis.com/auth/plus.login']
    // },
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   scopes: [
    //     'public_profile',
    //     'email',
    //     'user_likes',
    //     'user_friends'
    //   ]
    // },
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // {
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   // Whether the display name should be displayed in Sign Up page.
    //   requireDisplayName: true
    // }
  ],
  // Terms of service url.
  tosUrl: "https://www.soltell.com/commercial-terms-he",
};

class LoginPage extends React.Component {
  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview("/login");
    }
  }

  render() {
    const lang = this.props.i18n.language;
    return (
      <Wrapper>
        <Logo src={process.env.REACT_APP_LABEL_LOGO} alt="label logo" />
        {this.props.error && (
          <h3 style={{ color: "red" }}>{this.props.error.message}</h3>
        )}
        <h2>{this.props.t("Please Login")}</h2>
        <FirebaseUIAuth lang={lang} {...uiConfig} />
      </Wrapper>
    );
  }
}

export default withTranslation()(LoginPage);
