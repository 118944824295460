import Setting from "./Setting";
import AllowedValue from "./AllowedValue";

const validateBooleanValue = (val) => {
  if (typeof val === "string") {
    return val !== "false";
  }
  return !!val;
};

const PREF_DEFINITIONS = [
  {
    id: "powerGraphPrimaryAxisIsOnLeft",
    name: "Chart orientation",
    type: "select",
    // initial value should be true
    mutatorCallback: validateBooleanValue,
    allowedValues: [
      new AllowedValue("Vertical", false),
      new AllowedValue("Horizontal", true),
    ],
  },
  {
    id: "powerGraphExpandedByDefault",
    name: "Expand charts by default",
    type: "select",
    mutatorCallback: validateBooleanValue,
    allowedValues: [
      new AllowedValue("No", false),
      new AllowedValue("Yes", true),
    ],
  },
];

export default PREF_DEFINITIONS.map(
  (pref) =>
    new Setting(
      pref.id,
      pref.name,
      pref.type,
      pref.mutatorCallback,
      pref.allowedValues
    )
);
