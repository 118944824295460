import moment from "moment";

const DATE_FORMAT = {
  sheet: "YYYY-",
  db: "YYYY-MM-DD",
};

const propDict = {
  yield: "total",
  intact: "intact",
  change_pct: "change-pct",
  uptime: "uptime",
  uptime_ratio: "uptime_ratio",
  yield_ratio: "yield_ratio",
};

// if null/undefined are passed returns an empty string
const createString = (toString) => {
  if (toString !== 0 && !toString) {
    return "";
  }
  return `${toString}`;
};

// create dated key per the sheets format
const createDatedKey = (dateStr, key) => {
  return `y${dateStr}${key}`;
};

function dbToAppModel(sysId, response) {
  const result = {};
  response.forEach((res) => {
    // sanity check, ensuring that correct data is presented
    if (res.systemId !== sysId) {
      throw new Error(
        "annual system data fetched does not match requested system"
      );
    }
    const year = moment(res.year, DATE_FORMAT.db).format(DATE_FORMAT.sheet);
    Object.entries(res).forEach(([key, value]) => {
      if (propDict[key]) {
        result[createDatedKey(year, propDict[key])] = createString(value);
      }
    });
  });
  return result;
}

const createSheetDataFromDbData = (sysIds, dbResponses) => {
  return sysIds.reduce((acc, sysId, idx) => {
    acc[sysId] = { ...dbToAppModel(sysId, dbResponses[idx]) };
    return acc;
  }, {});
};

export default createSheetDataFromDbData;
