import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import {
  monthsDataToRenderArr,
  handleBarWidth,
} from "../../services/systemsArrayHelper";
import {
  postNewMessageToAws,
  postNewResponseToAws,
  changeMessageStatusAws,
} from "../../services/data";
import {
  SectionTitle,
  /* unused */
  // StatsContainer,
  // BarContainer,
  // MeasuresContainer
} from "../SystemDailyStatus/StyledComponents";
import MonthlyMessageRow from "./MonthlyMessageRow";
import UserRoles from "../../services/UserRoles";
import DownloadCsv from "./DownloadCsv";
import messageStatuses from "./messageStatuses";
import MonthlyTargetModal from "./MonthlyTargetModal";

/* unused */
// import { translate } from 'react-i18next/dist/commonjs/withTranslation';

const messageTemplate = {
  id: null,
  date: null,
  message: null,
  system: null,
  status: null,
  responses: null,
};

const FORMATTED_PERCENT_NUMBER_BOUNDS = {
  upper: 0,
  lower: -2,
};

const MonthlyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 30px 10px 30px;
`;
const MonthSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
`;
const Bar = styled.div`
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  margin-bottom: 20px;
`;
const SuccesBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: green;
  float: left;
  height: 10px;
  border-radius: ${(props) => (props.width === 100 ? "2em" : "2em 0 0 2em")};
`;
const FailBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: ${(props) => (props.width === 100 ? "#aaa" : "red")};
  float: left;
  height: 10px;
  border-radius: ${(props) => (props.width === 100 ? "2em" : "0 2em 2em 0")};
`;
const EPIAndYieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SuccessNumber = styled.span`
  color: green;
  font-size: 1.5em;
`;
const MinorFailNumber = styled.span`
  color: #e09926;
  font-size: 1.5em;
`;
const FailNumber = styled.span`
  color: red;
  font-size: 1.5em;
`;
const NeutralNumber = styled.span`
  color: orange;
  font-size: 1.5em;
`;
const YieldAndEPINumber = styled.span`
  color: green;
  font-size: 2em;
`;
const MeasureText = styled.span`
  font-size: 14px;
`;
const InfoField = styled.div`
  font-size: 14px;
  width: 100%;
  height: auto;
`;
const MonthsArrow = styled.span`
  ${(props) =>
    props.isDisabled
      ? ` margin-left: 10px;
    margin-right: 10px;
    cursor: not-allowed;
    color: #969696;
  `
      : ` margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    color: #000;
  `}
`;
const MonthName = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  color: #000;
`;

// month picker constants
const CHANGE_MONTH_TO = {
  next: "next",
  previous: "previous",
  nextYear: "nextYear",
  previousYear: "previousYear",
  first: "first",
  last: "last",
};

const NA_VALUE = "N/A";

/* unused */
// truncates a number to a single digit after the decimal point
// const clipNumberToSingleDecimal = num => {
//   if(num === null || !Number.isFinite(+num)){
//     return num;
//   }
//   return num.toString().split('.').reduce((acc, val, idx) => {
//     return !idx ? acc + val : acc + `.${val.charAt(0)}`
//   }, '');
// }

// returns a fixed decimal numeric value, or N/A value
const getValue = (value, decimal = 1) => {
  const numValue = Number.parseFloat(value);
  return Number.isNaN(numValue) ? "" : numValue.toFixed(decimal);
};

// returns a measured text with relevant units, or false
const getUnits = (value, units) => {
  const numValue = Number.parseFloat(value);
  const toRender = Number.isNaN(numValue) ? "" : units;
  return <MeasureText>{toRender}</MeasureText>;
};

// formats a percent number with interactive color options and decimal truncating
const formatPercentNumber = (
  rawValue,
  interactiveColor,
  decimal = 1,
  shouldUseFailColor = true
) => {
  const { upper: upperBound, lower: lowerBound } =
    FORMATTED_PERCENT_NUMBER_BOUNDS;
  const value = getValue(rawValue, decimal); // made to keep colors consistent when fractional part does not show
  const valueNumber = Number.parseFloat(value);
  const element = (
    <>
      {!Number.isNaN(valueNumber) && valueNumber > upperBound
        ? `+${value}`
        : value}
      {getUnits(value, "%")}
    </>
  );
  if (!interactiveColor || value > upperBound || value === NA_VALUE) {
    return <SuccessNumber dir="ltr">{element}</SuccessNumber>;
  } else if (value < upperBound && shouldUseFailColor) {
    if (value > lowerBound) {
      return <MinorFailNumber dir="ltr">{element}</MinorFailNumber>;
    }
    return <FailNumber dir="ltr">{element}</FailNumber>;
  }
  return <NeutralNumber dir="ltr">{element}</NeutralNumber>;
};

// TODO: fix botched component, should not contain props as state
class SystemMonthlyStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthsDataArray: [],
      choosenMonth: null,
      year: 0,
      messages: [],
      messagesLoading: true,
    };
    this.manageMonthlyData = this.manageMonthlyData.bind(this);
    this.switchMonth = this.switchMonth.bind(this);
    this.manageMessageData = this.manageMessageData.bind(this);
  }

  componentDidMount() {
    this.manageMonthlyData(this.props.sysStatus);
    this.manageMessageData(this.props.sysMessages);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sysStatus !== this.props.sysStatus) {
      this.manageMonthlyData(this.props.sysStatus);
    }
  }

  manageMonthlyData(monthlyData) {
    let monthlyDataArray = [];
    let existingMonthsArray = [];
    let monthsDataToRender = [];
    let year = 0;
    // TODO: make it easier map -> filter -> map
    for (let dateAndFieldName of Object.keys(monthlyData).sort()) {
      const dateMatches = /(\d{4})(\d{2})-\w+/g.exec(dateAndFieldName);
      const fieldNameMatches = /\d{4}\d{2}-(\w+)/g.exec(dateAndFieldName);
      if (dateMatches) {
        const fieldName = fieldNameMatches ? fieldNameMatches[1] : "";
        const month = +dateMatches[2];
        year = +dateMatches[1];
        // add month/year to existing month array
        let found = false; // this was done in order to search from the end, to keep lower running times
        for (let i = existingMonthsArray.length - 1; i >= 0 && !found; --i) {
          found =
            existingMonthsArray[i].month === month &&
            existingMonthsArray[i].year === year;
        }
        if (!found) {
          existingMonthsArray.push({ month, year });
        }
        monthlyDataArray.push({
          month,
          year,
          fieldName,
          value: monthlyData[dateAndFieldName],
        });
      }
    }
    // get all month data objects
    // [{ month: ..., year: ..., values: { yield: ..., epi: ... }}, ...]
    const payload = { existingMonthsArray, monthlyDataArray };
    const props = {
      t: this.props.t,
      sysMessages: this.props.sysMessages,
      moment,
    };
    monthsDataToRender = monthsDataToRenderArr(payload, props);
    this.setState({
      monthsDataArray: monthsDataToRender,
      choosenMonth: monthsDataToRender.length
        ? monthsDataToRender[monthsDataToRender.length - 1]
        : null,
      switchNextDisabled: true,
      year,
    });
  }

  manageMessageData(messages) {
    this.setState({ messages });
  }

  // switchMonthOld(type) {
  //   this.setState((state) => {
  //     const { choosenMonth, monthsDataArray } = state;
  //     const choosenMonthIndex = choosenMonth.index;
  //     const typeIsNext = type === CHANGE_MONTH_TO.next;
  //     let newMonth = choosenMonth;

  //     if (typeIsNext || type === CHANGE_MONTH_TO.previous) {
  //       const nextMonth =
  //         monthsDataArray[choosenMonthIndex + (typeIsNext ? 1 : -1)];
  //       newMonth = nextMonth ? nextMonth : choosenMonth;
  //     } else if (type === CHANGE_MONTH_TO.first) {
  //       newMonth = monthsDataArray[0];
  //     } else if (type === CHANGE_MONTH_TO.last) {
  //       newMonth = monthsDataArray[monthsDataArray.length - 1];
  //     }

  //     return {
  //       choosenMonth: newMonth,
  //       year: newMonth.year,
  //     };
  //   });
  // }

  switchMonth = (type) => {
    const sysId = this.props.sysInfo.id;
    let newMonth = moment(this.props.sysStatus.loadedMonth);
    if (type === CHANGE_MONTH_TO.previous) {
      newMonth.subtract(1, "month");
    } else if (type === CHANGE_MONTH_TO.previousYear) {
      newMonth.subtract(1, "year");
    } else if (type === CHANGE_MONTH_TO.next) {
      newMonth.add(1, "month");
    } else if (type === CHANGE_MONTH_TO.nextYear) {
      newMonth.add(1, "year");
    }

    if (newMonth.isAfter(moment())) {
      newMonth = moment();
    }

    const cmpFormat = "YYYY-MM";
    if (
      this.props.sysStatus.loadedMonth.format(cmpFormat) !==
      newMonth.format(cmpFormat)
    ) {
      this.props.loadMonthlyInsights(sysId, newMonth.startOf("month"));
    }
  };

  // add a new message to mlog
  addNewMessage = () => {
    if (!this.props.userRole.privileges.postToMlog) {
      return;
    }

    const t = this.props.t;
    const day = +window.prompt(t("Choose a day of the month"));
    if (!day || !isFinite(day)) {
      return;
    }
    const { month, year } = this.state.choosenMonth;
    const date = moment(`${day}-${month}-${year}`, "D-M-YYYY");
    if (!date.isValid()) {
      return;
    }
    const newMessage = window.prompt(t("Add a new message"));
    if (!newMessage || !newMessage.trim()) {
      return;
    }

    this.props
      .getIdToken()
      .then((token) =>
        postNewMessageToAws(
          token,
          date,
          this.props.sysInfo.id,
          newMessage.trim()
        )
      )
      .then((result) => {
        this.setState((state) => {
          // for some reason any other more elegant way does not re-render the component
          const messageToAdd = { ...messageTemplate };
          messageToAdd.id = result.id;
          messageToAdd.date = date.format("DD.MM.YYYY");
          messageToAdd.message = result.message;
          messageToAdd.system = this.props.sysInfo.id;
          messageToAdd.status = result.status;
          messageToAdd.responses = [];
          const messages = [...state.messages, messageToAdd];
          const choosenMonth = { ...state.choosenMonth };
          // sort new message into place
          choosenMonth.messages = [...choosenMonth.messages, messageToAdd].sort(
            ({ date: d1 }, { date: d2 }) => {
              return moment(d1, "DD.MM.YYYY").isBefore(moment(d2, "DD.MM.YYYY"))
                ? -1
                : 1;
            }
          );
          const monthsDataArray = [...state.monthsDataArray];
          monthsDataArray[choosenMonth.index] = choosenMonth;
          return {
            messages,
            choosenMonth,
            monthsDataArray,
          };
        });
      })
      .catch((e) => {
        console.error(e);
        window.alert(t("There was an error, please try again"));
      });
  };

  updateMessageStatus = (msg, newStatus) => {
    if (
      !msg ||
      !messageStatuses[msg.status] ||
      !messageStatuses[newStatus] ||
      !this.props.userRole.privileges.postToMlog
    ) {
      return;
    }

    return this.props
      .getIdToken()
      .then((token) => changeMessageStatusAws(token, Number(msg.id), newStatus))
      .then((result) => {
        this.setState((state) => {
          let messages = [...state.messages];
          let choosenMonth = { ...state.choosenMonth };
          if (messageStatuses[result.status].shouldHide) {
            messages = messages.filter((msg) => msg.id !== result.id);
            choosenMonth.messages = choosenMonth.messages.filter(
              (msg) => msg.id !== result.id
            );
          } else {
            let idx = messages.findIndex((msg) => msg.id === result.id);
            messages[idx].status = result.status;
            idx = choosenMonth.messages.findIndex(
              (msg) => msg.id === result.id
            );
            choosenMonth.messages[idx].status = result.status;
          }
          const monthsDataArray = [...state.monthsDataArray];
          monthsDataArray[choosenMonth.index] = choosenMonth;
          return {
            messages,
            choosenMonth,
            monthsDataArray,
          };
        });
      });
  };

  updateResponseString = (msg, newResponse) => {
    if (!this.props.userRole.privileges.postToMlog || !msg) {
      return;
    }
    if (!newResponse || !newResponse.trim()) {
      return;
    }

    return this.props
      .getIdToken()
      .then((token) =>
        postNewResponseToAws(
          token,
          Number(msg.id),
          newResponse.trim(),
          this.props.userRole.name
        )
      )
      .then((result) => {
        this.setState((state) => {
          const messages = [...state.messages];
          const idx = messages.findIndex((m) => m.id === msg.id);
          messages[idx].responses.push(result);
          return { messages };
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  createDownloadHint = () => {
    const sysName = this.props.sysInfo.name || "";
    const { month, year } = this.state.choosenMonth;
    return `monthly_report_${sysName}_${month}-${year}.csv`;
  };

  calcNormalizedYield = (sysPower, monthlYield, defaultValue = NaN) => {
    const value = Number.parseFloat(monthlYield) / Number.parseFloat(sysPower);
    if (!Number.isFinite(value)) {
      return defaultValue;
    }
    return value;
  };

  prepareDataForDownload = () => {
    const MESSAGES_DELIM = " | ";
    const choosenMonth = this.state.choosenMonth;
    const { values } = choosenMonth;
    const monthlyYield = Number.parseFloat(values.yield);
    const normalizedYield = this.calcNormalizedYield(
      this.props.sysInfo.power,
      monthlyYield
    );
    const rating = Number.parseFloat(values.rating);
    const epi = Number.parseFloat(values.epi);
    const expect = Number.parseFloat(values.expect);
    const expratio = Number.parseFloat(values.expratio);
    const cumyear = Number.parseFloat(values.cumyear);
    const preyield = Number.parseFloat(values.preyield);
    const uptime = Number.parseFloat(values.uptime);
    const exp_uptime = Number.parseFloat(values.exp_uptime);
    const exp_uptime_ratio = Number.parseFloat(values.exp_uptime_ratio);

    const messages = (choosenMonth.messages || [])
      .map(({ date, message, status, responses }) => {
        const cleanDate = date.replaceAll(",", ";");
        const cleanMessage = message.replaceAll(",", ";");
        const cleanStatus = status.replaceAll(",", ";");
        const cleanResponses = responses
          .map((res) => `"${res.responseMessage.replaceAll(",", ";")}"`)
          .join(" * ");
        return `${cleanDate} ${cleanStatus} - "${cleanMessage}" : [${cleanResponses}]`;
      })
      .join(MESSAGES_DELIM);

    const data = [
      {
        name: this.props.sysInfo.name || "",
        month: choosenMonth.month || "",
        year: choosenMonth.year || "",
        yield: !Number.isNaN(monthlyYield) ? monthlyYield : "",
        normalizedYield: !Number.isNaN(normalizedYield) ? normalizedYield : "",
        rating: !Number.isNaN(rating) ? rating : "",
        epi: !Number.isNaN(epi) ? epi : "",
        expect: !Number.isNaN(expect) ? expect : "",
        expratio: !Number.isNaN(expratio) ? expratio : "",
        cumyear: !Number.isNaN(cumyear) ? cumyear : "",
        preyield: !Number.isNaN(preyield) ? preyield : "",
        uptime: !Number.isNaN(uptime) ? uptime : "",
        exp_uptime: !Number.isNaN(exp_uptime) ? exp_uptime : "",
        exp_uptime_ratio: !Number.isNaN(exp_uptime_ratio)
          ? exp_uptime_ratio
          : "",
        messages: messages,
      },
    ];
    return data;
  };

  renderMonthSwitcher = () => {
    const loadedMonth = this.props.sysStatus.loadedMonth;
    const t = this.props.t;
    const disableNext = loadedMonth.isSameOrAfter(moment(), "month");
    return (
      <MonthSwitcher>
        <MonthsArrow
          onClick={() => this.switchMonth(CHANGE_MONTH_TO.previousYear)}
        >
          {"<<"}
        </MonthsArrow>
        <MonthsArrow onClick={() => this.switchMonth(CHANGE_MONTH_TO.previous)}>
          {"<"}
        </MonthsArrow>
        <MonthName>
          {t(loadedMonth.format("MMMM"))} {loadedMonth.format("YYYY")}
        </MonthName>
        <MonthsArrow
          isDisabled={disableNext}
          onClick={() => this.switchMonth(CHANGE_MONTH_TO.next)}
        >
          {">"}
        </MonthsArrow>
        <MonthsArrow
          isDisabled={disableNext}
          onClick={() => this.switchMonth(CHANGE_MONTH_TO.nextYear)}
        >
          {">>"}
        </MonthsArrow>
      </MonthSwitcher>
    );
  };

  render() {
    const { t } = this.props;
    const { monthsDataArray, choosenMonth } = this.state;
    if (!monthsDataArray.length || !choosenMonth) {
      return (
        <MonthlyDataContainer>
          {this.renderMonthSwitcher()}
          <div>{t("No status data found")}</div>
        </MonthlyDataContainer>
      );
    }
    const { values } = choosenMonth;
    // FIXED: many redundant searches in this array would be avoided later on
    const yieldField = values.yield || "";
    const epiField = values.epi || "";
    const ratingField = values.rating || "";
    const preyieldField = values.preyield || "";
    const cumyearField = values.cumyear || "";
    const expectedRatio = values.expratio || "";
    const expectField = values.expect || "";
    const uptimeField = values.uptime || "";
    const expUptimeField = values.exp_uptime || "";
    const expUptimeRatioField = values.exp_uptime_ratio || "";

    const normalizedYield = this.calcNormalizedYield(
      this.props.sysInfo.power,
      yieldField,
      ""
    );

    return (
      <MonthlyDataContainer>
        {this.renderMonthSwitcher()}
        <EPIAndYieldContainer>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={12} sm={4}>
              <SectionTitle>{t("Monthly Performance")}</SectionTitle>
            </Col>
            <Col xs={12} sm={8}>
              {choosenMonth ? (
                <Bar>
                  <SuccesBar width={handleBarWidth(epiField)} />
                  <FailBar width={100 - handleBarWidth(epiField)} />
                </Bar>
              ) : (
                <span>{t("Loading")}</span>
              )}
              <Row
                style={{
                  marginBottom: "20px",
                  marginRight: this.props.i18n.dir() === "ltr" ? "20%" : "0%",
                  marginLeft: this.props.i18n.dir() === "rtl" ? "20%" : "0%",
                }}
              >
                <Col xs={12} sm={12} lg={4}>
                  {t("Yield")}:
                  <YieldAndEPINumber>
                    {getValue(yieldField)}
                    {getUnits(yieldField, "kWh")}
                  </YieldAndEPINumber>
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  {t("Health")}:
                  <YieldAndEPINumber>
                    {getValue(epiField)}
                    {getUnits(epiField, "%")}
                  </YieldAndEPINumber>
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  {t("Uptime")}:
                  <YieldAndEPINumber>
                    {getValue(uptimeField)}
                    {getUnits(uptimeField, "%")}
                  </YieldAndEPINumber>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px", marginLeft: 0, marginRight: 0 }}>
            <InfoField>
              <span>
                {t("Average regional rating")}:{" "}
                {formatPercentNumber(ratingField, true, 1, false)}
              </span>
            </InfoField>
            <InfoField>
              <span>
                {t("Normalized monthly yield")}:{" "}
                <SuccessNumber>
                  {getValue(normalizedYield)}
                  {getUnits(normalizedYield, "kWh / kW")}
                </SuccessNumber>
              </span>
            </InfoField>
            {preyieldField && (
              <InfoField>
                <span>
                  {t("Parallel monthly yield last year")}:{" "}
                  <SuccessNumber>
                    {getValue(preyieldField)}
                    {getUnits(preyieldField, "kWh")}
                  </SuccessNumber>
                </span>
              </InfoField>
            )}
            <InfoField>
              <span>
                {t("Since the beginning of")}:{" "}
                <SuccessNumber>
                  {getValue(cumyearField)}
                  {getUnits(cumyearField, "kWh")}
                </SuccessNumber>
              </span>
            </InfoField>
            {!expectedRatio
              ? false
              : expectedRatio === NA_VALUE || (
                  <InfoField>
                    <span>
                      {t("Output vs expected ratio")}:{" "}
                      {formatPercentNumber(expectedRatio, true)}{" "}
                      {getValue(expectField) ? (
                        <span style={{ fontSize: "1.5em" }} dir="ltr">
                          {" "}
                          ({getValue(expectField)}
                          <MeasureText>kWh</MeasureText>)
                        </span>
                      ) : null}
                    </span>
                  </InfoField>
                )}
            <InfoField>
              <span>
                {t("Uptime vs expected ratio")}:{" "}
                {formatPercentNumber(expUptimeRatioField, true)}{" "}
                {getValue(expUptimeField) ? (
                  <span style={{ fontSize: "1.5em" }} dir="ltr">
                    {" "}
                    ({getValue(expUptimeField)}
                    <MeasureText>%</MeasureText>)
                  </span>
                ) : null}
              </span>
            </InfoField>
            {this.props.userRole.privileges.postToMlog ? (
              <MonthlyTargetModal
                sysId={this.props.sysInfo.id}
                sysName={this.props.sysInfo.name || ""}
                year={choosenMonth.year}
                userName={this.props.userName}
                getIdToken={this.props.getIdToken}
              />
            ) : null}
          </Row>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <h4 style={{ fontSize: "14px" }}>
              <b onClick={this.addNewMessage}>{t("Monthly Event Log")}</b>
            </h4>
            {!choosenMonth.messages.length
              ? t("No messages")
              : // older version's key had index as undefined property
                choosenMonth.messages.map((msg, index) => (
                  <MonthlyMessageRow
                    key={`${index}_${msg.message}`}
                    msg={msg}
                    privileges={this.props.userRole.privileges}
                    updateMessageResponse={this.updateResponseString}
                    updateMessageStatus={this.updateMessageStatus}
                  />
                ))}
          </Row>
        </EPIAndYieldContainer>
        <DownloadCsv
          downloadHint={this.createDownloadHint()}
          prepareDataForDownload={this.prepareDataForDownload}
        />
      </MonthlyDataContainer>
    );
  }
}

SystemMonthlyStatus.propTypes = {
  sysStatus: PropTypes.object,
  sysInfo: PropTypes.object,
  sysMessages: PropTypes.array,
  userRole: PropTypes.object,
  getIdToken: PropTypes.func,
};
SystemMonthlyStatus.defaultProps = {
  sysStatus: null,
  sysInfo: null,
  sysMessages: [],
  userRole: UserRoles.demo,
  getIdToken: () => null,
};
export default withTranslation()(SystemMonthlyStatus);
