// import firebase from 'firebase';
// import * as firebaseui from 'firebaseui';

const firebase = global.firebase;
// const firebaseui = global.firebaseui;

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const defaultApp = firebase.initializeApp(config);
// export const ui = new firebaseui.auth.AuthUI(firebase.auth());
export default firebase;
