import * as React from "react";
import Issue from "./Issue";
import { ListGroup } from "react-bootstrap";
import userRoles from "../../services/UserRoles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
// import styled from "styled-components";

import messageGenerator from "@soltell/soltell-message-generator";
import labelStyles from "../../styles/index";

const { fixedTs, extractIssues } = messageGenerator;
const {
  IssuesList: { DailyFooter },
} = labelStyles;

class IssuesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if ("then" in fixedTs.ready) {
      fixedTs.ready.then(() => this.forceUpdate());
    }
  }

  render() {
    const props = this.props;
    const filterIssues = userRoles[props.userRole].toFilter || ["*"];
    const issuesArr = extractIssues(
      props.sysStatus,
      props.sysInfo.subsystems,
      filterIssues
    );
    const {
      t,
      sysStatus: { emptyDay },
    } = props;
    const frozen = props.sysInfo.frozen;
    const label = process.env.REACT_APP_LABEL_TITLE;
    const lng = window.localStorage["i18nextLng"];
    const messagesTranslator = fixedTs[lng] ? fixedTs[lng] : () => t("Loading");

    if (issuesArr.length === 0) {
      let issues = [];
      // if data exists for the day, show that everything is fine
      if (!emptyDay) {
        issues.push({ msg: t("No major issues detected"), severity: 0 });
        // no data for day, show no communication
      } else {
        issues.push({
          msg: messagesTranslator("msg_f-comm_nocomm"),
          severity: 2.5,
        });
        // frozen system, show extra message to indicate
        if (frozen) {
          issues.push({
            msg: messagesTranslator("freeze_sys", { label }),
            severity: 2.5,
          });
        }
      }
      return (
        <div>
          <ListGroup>
            {issues.map(({ msg, severity }, idx) => (
              <Issue
                key={idx}
                msg={msg}
                severity={severity}
                params={{}}
                i18n={this.props.i18n}
              />
            ))}
          </ListGroup>
          <DailyFooter
            isSubscribed={props.isSubscribed}
            hasIssues={false}
            subscribeString={t("Subscribe to service")}
            goToServiceString={t("Go to Services Index")}
          />
        </div>
      );
    }

    return (
      <div>
        <ListGroup>
          {issuesArr.map((issue, idx) => (
            <Issue
              key={idx}
              messagesTranslator={messagesTranslator}
              msg={issue.msg}
              severity={issue.severity}
              params={"params" in issue ? issue.params : {}}
              i18n={this.props.i18n}
            />
          ))}
        </ListGroup>
        <DailyFooter
          isSubscribed={props.isSubscribed}
          hasIssues={true}
          subscribeString={t("Subscribe to service")}
          goToServiceString={t("Go to Services Index")}
        />
      </div>
    );
  }
}
IssuesList.propTypes = {
  sysStatus: PropTypes.object, // status data to display
  sysInfo: PropTypes.object, // static system info
  isSubscribed: PropTypes.bool,
  userRole: PropTypes.string,
};
IssuesList.defaultProps = {
  sysStatus: null,
  sysInfo: null,
  isSubscribed: false,
};

export default withTranslation()(IssuesList);
