import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

const Container = styled.div``;

const MsgContainer = (props) => {
  return <Container>{props.t(props.text)}</Container>;
};

MsgContainer.propTypes = {
  text: PropTypes.string,
};
MsgContainer.defaultProps = {
  text: "",
};

export default withTranslation()(MsgContainer);
