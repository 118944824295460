import * as React from "react";
import { withTranslation } from "react-i18next";

const SubmitSuccess = (props) => {
  return (
    <div>
      <h1>{props.t("Thank you!")}</h1>
      {props.t("Your form was submitted and we will be in touch soon")}
    </div>
  );
};

export default withTranslation()(SubmitSuccess);
