import * as React from "react";
import PropTypes from "prop-types";
import LoadingAnim from "../LoadingAnim/ThreeDots";
import {
  systemsWithDataArr,
  setEmptySystems,
  sortedSubsystemsArr,
  sortedNotEmptySystemsBySeverityAndEPI,
} from "../../services/systemsArrayHelper";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import { withTranslation } from "react-i18next";
import UserRoles from "../../services/UserRoles";

import {
  Grid,
  Col,
  Row,
  PanelGroup,
  Collapse,
  ButtonToolbar,
  Button,
  Badge,
} from "react-bootstrap";
import SystemStatusPanel from "../SystemStatusPanel";
import SystemWideCalendar from "./SystemWideCalendar";

const POLL_INTERVAL = 1000 * 60 * 60;

// theme for the autosuggest search bar
const autosuggestTheme = {
  container: {
    // width: "30%",
    top: "8px",
    zIndex: "1030",
    // for smaller screens, where the searchbox is overlayed on the logo
    // left: window.innerWidth <= 430 ? "48%" : "40%",
    // position: "fixed",
  },
  suggestionsList: {
    listStyleType: "none",
    width: "fit-content",
    minWidth: "30%",
    zIndex: "100",
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255)",
    padding: "2px 0",
    fontSize: "90%",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%",
  },
  suggestion: {
    width: "100%",
    height: "auto",
    fontSize: "18px",
    padding: "5px",
    backgroundColor: "white",
    cursor: "pointer",
  },
  suggestionHighlighted: {
    backgroundColor: "#eee",
  },
};

const AutocompleteInput = styled.input`
  width: 100%;
  top: 8px;
  z-index: 1030;
  left: 40%;
  border: 0.5px solid #000;
  border-radius: 4px;
  font-size: 18px;
  padding: 5px;
  :focus {
    outline-width: 0;
  }
  @media (max-width: 430px) {
    left: 48%;
  }
`;
const SystemListHeading = styled(Row)`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  font-size: 20px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FilterBar = styled(ButtonToolbar)`
  display: flex;
  flex-wrap: wrap;
`;

const autocompleteContainerStyle = {
  marginBottom: "20px",
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: "15px",
  paddingLeft: "15px",
};

class OrgSystemsList extends React.Component {
  // handle to polling timeout
  timer;

  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.state = {
      subsystems: {},
      parentSystemIds: [],
      systems: [],
      searchString: "",
      activeKey: null,
      suggestions: [],
      selectedFilters: {},
      filteredSystems: [],
      searchBarOpened: false,
      filterBarOpened: false,
    };
    this.startPolling = this.startPolling.bind(this);
    this.stopPolling = this.stopPolling.bind(this);
    this.fillSubsystems = this.fillSubsystems.bind(this);
    this.manageSystemsData = this.manageSystemsData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  startPolling() {
    this.timer = setTimeout(() => {
      this.props.loadStatuses();
      this.startPolling();
    }, POLL_INTERVAL);
  }

  fillSubsystems(system) {
    this.setState((state) => {
      let subsystems = { ...state.subsystems };
      if (subsystems[system.parent_id]) {
        if (
          subsystems[system.parent_id].findIndex(
            (sys) => sys.id === system.id
          ) === -1
        ) {
          const sortedSubsystems = sortedSubsystemsArr(system, subsystems);
          subsystems[system.parent_id] = sortedSubsystems; // TODO:DONE fix to this.setState
        }
      } else {
        subsystems[system.parent_id] = [system]; // TODO:DONE fix to this.setState
      }
      return { subsystems };
    });
  }

  stopPolling() {
    clearTimeout(this.timer);
  }

  manageSystemsData() {
    const { sysStatus } = this.props;
    const systems = this.props.orgData.systems;
    const systemsWithData = systemsWithDataArr(
      systems,
      sysStatus,
      this.fillSubsystems
    );
    const sortedSystemsWithData = sortedNotEmptySystemsBySeverityAndEPI(
      systemsWithData,
      sysStatus
    );

    // used to determine if the first panel should be expanded
    const activeSysNum = systems.filter((sys) => !sys.parent_id).length;

    const emptySystems = setEmptySystems(systems, sysStatus);
    this.setState(
      {
        systems: sortedSystemsWithData.concat(emptySystems),
        activeKey: activeSysNum === 1 ? 0 : null,
      },
      () => this.clearSelectedFilters(true)
    );
  }

  clearSelectedFilters = (keepActiveKey = false) => {
    this.setState((state) => {
      return {
        selectedFilters: {},
        filteredSystems: [...state.systems],
        activeKey: keepActiveKey ? state.activeKey : null,
      };
    });
  };

  changeSelectedFilters = (filterId) => {
    this.setState((state, props) => {
      const filtersObject = props.orgData.filters;
      if (!filtersObject) {
        return {
          selectedFilters: {},
          filteredSystems: [...state.systems],
          activeKey: null,
        };
      }

      const filter = filtersObject[filterId];
      if (!filter) {
        return {
          selectedFilters: {},
          filteredSystems: [...state.systems],
          activeKey: null,
        };
      }

      const selectedFilters = { ...state.selectedFilters };
      if (selectedFilters[filterId]) {
        delete selectedFilters[filterId];
      } else {
        selectedFilters[filterId] = { ...filter };
      }
      const filteredSysIds = Object.values(selectedFilters)
        .map((v) => v.systemIds)
        .flat();

      return {
        selectedFilters,
        filteredSystems: !filteredSysIds.length
          ? [...state.systems]
          : state.systems.filter((sys) => filteredSysIds.includes(sys.id)),
        activeKey: null,
      };
    });
  };

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  componentDidMount() {
    this.manageSystemsData();
    this.startPolling();
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reloadingAllSystems && !this.props.reloadingAllSystems) {
      this.manageSystemsData();
    }
  }

  setAutoCompleteRef = (e) => {
    this.autocomplete = e;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState((state) => {
      const lowerCasedValue = value.trim().toLowerCase();
      return {
        suggestions: state.filteredSystems.filter((item) =>
          item.lowerCaseName.includes(lowerCasedValue)
        ),
      };
    });
  };

  onSuggestionSelected = (e, { suggestion }) => {
    const systems = this.state.filteredSystems;
    let systemPanelKey = suggestion.parent_id
      ? systems.findIndex((sys) => sys.id === suggestion.parent_id)
      : systems.findIndex((sys) => sys.id === suggestion.id);
    this.setState({ activeKey: systemPanelKey }, () => {
      const systemItem = document.getElementById(`${suggestion.id}-sysId`);
      systemItem.scrollIntoView({ behavior: "smooth" });
    });
  };

  renderDatePickerOrUpdated = () => {
    const { systemWideDate, dateFormat, changeSystemWideDate } = this.props;
    return (
      <Col xs={12} sm={3}>
        <SystemWideCalendar
          currentDate={systemWideDate}
          dateFormat={dateFormat}
          onChangeSystemWideDate={changeSystemWideDate}
        />
      </Col>
    );
  };

  handleSearchButton = (ev) => {
    ev.preventDefault();
    this.setState({ filterBarOpened: false }, () =>
      this.setState((state) => {
        return { searchBarOpened: !state.searchBarOpened };
      })
    );
  };

  handleFilterButton = (ev) => {
    ev.preventDefault();
    this.setState({ searchBarOpened: false }, () =>
      this.setState((state) => {
        return { filterBarOpened: !state.filterBarOpened };
      })
    );
  };

  render() {
    if (!this.state.systems.length || this.props.reloadingAllSystems)
      return <LoadingAnim />;
    if (!this.props.orgData) {
      return null;
    } else {
      const { sysnum, syspower, name, filters } = this.props.orgData;
      const isFiltered = Object.keys(this.state.selectedFilters).length > 0;
      const currentSysnum = isFiltered
        ? this.state.filteredSystems.length
        : sysnum;
      const currentSyspower = isFiltered
        ? this.state.filteredSystems.reduce((sum, acc) => {
            return sum + acc.power;
          }, 0)
        : syspower;
      const shouldRenderFilters = filters && Object.keys(filters);
      // const firstSystemId = this.props.orgData.systems[0].id
      return (
        <div>
          <div style={autocompleteContainerStyle}>
            <Grid>
              {sysnum && sysnum !== "N/A" ? (
                <>
                  <SystemListHeading>
                    <Col xs={12} sm={9}>
                      <b>{name} </b>
                      <div>
                        <b>{(+currentSyspower).toFixed(2)}</b>
                        {`${this.props.t("kW")} `}
                      </div>
                    </Col>
                    {this.renderDatePickerOrUpdated()}
                  </SystemListHeading>
                  <Row style={{ marginLeft: 0, marginRight: 0 }}>
                    <Col xs={6} sm={10}>
                      <span style={{ fontSize: "18px" }}>
                        {currentSysnum} {this.props.t("Systems")}
                      </span>
                    </Col>
                    <Col xs={6} sm={2} style={{ textAlign: "end" }}>
                      <Button
                        bsStyle="link"
                        bsSize="xsmall"
                        onClick={this.handleSearchButton}
                      >
                        <span className="material-icons">search</span>
                      </Button>
                      {!shouldRenderFilters ? null : (
                        <Button
                          bsStyle="link"
                          bsSize="xsmall"
                          onClick={this.handleFilterButton}
                        >
                          <span className="material-icons">filter_alt</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Collapse in={this.state.searchBarOpened}>
                      <Col xs={8} sm={4}>
                        <Autosuggest
                          ref={this.setAutoCompleteRef}
                          suggestions={this.state.suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={() =>
                            this.setState({ suggestions: [] })
                          }
                          getSuggestionValue={(suggestion) => suggestion.name}
                          inputProps={{
                            placeholder: this.props.t("Search for systems"),
                            value: this.state.searchString,
                            type: "search",
                            onChange: (ev, { newValue }) => {
                              ev.preventDefault();
                              this.setState({
                                searchString: newValue,
                              });
                            },
                          }}
                          shouldRenderSuggestions={() => true}
                          focusInputOnSuggestionClick={false}
                          onSuggestionSelected={this.onSuggestionSelected}
                          theme={autosuggestTheme}
                          renderSuggestion={(suggestion) => (
                            <div>{suggestion.name}</div>
                          )}
                          renderInputComponent={(inputProps) => {
                            return <AutocompleteInput {...inputProps} />;
                          }}
                        />
                      </Col>
                    </Collapse>
                  </Row>
                  {!shouldRenderFilters ? null : (
                    <Row>
                      <Collapse in={this.state.filterBarOpened}>
                        <Col xs={12} sm={12}>
                          <FilterBar>
                            {Object.entries(this.props.orgData.filters).map(
                              ([k, v]) => {
                                const numberOfSystems = v.systemIds.length;
                                return (
                                  <Button
                                    key={k}
                                    bsStyle={
                                      !this.state.selectedFilters[k]
                                        ? "default"
                                        : "primary"
                                    }
                                    bsSize="small"
                                    disabled={numberOfSystems <= 0}
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      this.changeSelectedFilters(v.id);
                                    }}
                                  >
                                    {v.name} <Badge>{numberOfSystems}</Badge>
                                  </Button>
                                );
                              }
                            )}
                            <Button
                              bsStyle="danger"
                              bsSize="small"
                              onClick={(ev) => {
                                ev.preventDefault();
                                this.clearSelectedFilters(false);
                              }}
                            >
                              {this.props.t("Clear filters")}
                            </Button>
                          </FilterBar>
                        </Col>
                      </Collapse>
                    </Row>
                  )}
                </>
              ) : null}
            </Grid>
          </div>
          <Grid>
            <Row>
              <Col xs={12}>
                <PanelGroup
                  accordion
                  id="Systems Group"
                  activeKey={this.state.activeKey}
                  onSelect={this.handleSelect}
                >
                  {this.state.filteredSystems.map((sys, sysIndex) => {
                    const subsystemsStatus = (
                      this.state.subsystems[sys.id] || []
                    ).map(({ id: subId }) => {
                      return {
                        statusDaily: this.props.sysStatus[subId],
                        statusMonthly: this.props.sysStatusMonthly[subId],
                        statusAnnual: this.props.sysStatusAnnual[subId],
                      };
                    });
                    return (
                      <SystemStatusPanel
                        key={sysIndex}
                        index={sysIndex}
                        isSelected={this.state.activeKey === sysIndex}
                        name={sys.name}
                        updated={sys.status_update}
                        subsystems={this.state.subsystems[sys.id]}
                        subsystemsStatus={subsystemsStatus}
                        isParentSystem={sys.isParentSys}
                        // toggleSystem={(index) => this.toggleSystem(index)}
                        status={
                          this.props.sysStatus[sys.id]
                            ? this.props.sysStatus[sys.id]
                            : null
                        }
                        statusMonthly={
                          sys.id in this.props.sysStatusMonthly
                            ? this.props.sysStatusMonthly[sys.id]
                            : {}
                        }
                        statusAnnual={
                          sys.id in this.props.sysStatusAnnual
                            ? this.props.sysStatusAnnual[sys.id]
                            : []
                        }
                        info={sys}
                        yearMessages={this.props.yearMessages}
                        isDemo={this.props.orgData.isDemo}
                        isSubscribed={this.props.orgData.isEnabled}
                        isFetching={this.props.isFetching}
                        userRole={this.props.userRole}
                        loadDailyInsights={this.props.loadDailyInsights}
                        loadMonthlyInsights={this.props.loadMonthlyInsights}
                        lastSysUpdated={this.props.lastSysUpdated[sys.id]}
                        getIdToken={this.props.getIdToken}
                        userName={this.props.userName}
                        preferences={this.props.preferences}
                      />
                    );
                  })}
                </PanelGroup>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}
OrgSystemsList.propTypes = {
  orgData: PropTypes.object,
  sysStatus: PropTypes.object, // status data to display
  sysStatusAnnual: PropTypes.object, // status data to display
  isFetching: PropTypes.bool,
  sysStatusMonthly: PropTypes.object,
  yearMessages: PropTypes.object,
  loadStatuses: PropTypes.func,
  userRole: PropTypes.object,
  loadDailyInsights: PropTypes.func,
  lastSysUpdated: PropTypes.object,
  lastOrgUpdated: PropTypes.string,
};
OrgSystemsList.defaultProps = {
  orgData: null,
  sysStatus: {},
  sysStatusAnnual: {},
  sysStatusMonthly: null,
  isFetching: true,
  yearMessages: null,
  userRole: UserRoles.demo,
  loadStatuses: (sysIds) => null,
  lastSysUpdated: {},
  lastOrgUpdated: "",
};

export default withTranslation()(OrgSystemsList);
