import React from "react";
import "./App.css";
// import FirebaseUIAuth from './FirebaseUIAuth';
import firebase from "./firebase";

import { loadUserData } from "./services/data";

import LoginPage from "./pages/Login";
// import NewUserPage from './pages/NewUser'
import Splash from "./pages/Splash";
import UserApp from "./UserApp";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: null,
      userExtra: null,
      error: null,
    };
  }

  componentDidMount() {
    // user auth and data loading
    const that = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        that.setState({
          loading: false,
        });
        return;
      }

      user
        .getIdToken(false)
        .then((token) => loadUserData(token))
        .then((userExtra) => {
          that.setState({
            loading: false,
            user,
            userExtra,
          });
        })
        .catch((err) => {
          console.log("Error loading user extra data", err.message);
          that.setState({
            loading: false,
            user: null,
            userExtra: null,
            error: err,
          });
        });
    });
  }

  renderInner() {
    if (this.state.loading) {
      return <Splash>Loading...</Splash>;
    }
    if (!this.state.user || !this.state.userExtra) {
      return <LoginPage error={this.state.error} />;
    }
    // if (!this.state.userExtra) {
    //   return <NewUserPage userData={this.state.user}/>
    // }
    return <UserApp user={this.state.user} userExtra={this.state.userExtra} />;
  }

  render() {
    return (
      <div className={`App ${this.props.i18n.dir()}`}>{this.renderInner()}</div>
    );
  }
}

export default withTranslation()(App);
