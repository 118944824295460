const severityColors = {
  // green
  successColor: {
    color: "#155724",
    backgroundColor: "#45C777",
    borderColor: "#c3e6cb",
  },
  // gray
  commColor: {
    color: "#1b1e21",
    backgroundColor: "#A1ABB3",
    borderColor: "#c6c8ca",
  },
  // red
  dangerColor: {
    color: "#721c24",
    backgroundColor: "#FC635B",
    borderColor: "#f5c6cb",
  },
  // orange
  warningColor: {
    color: "#755404",
    backgroundColor: "#FDB643",
    borderColor: "#ffeeba",
  },
  // teal
  primaryColor: {
    // for notifications
    color: "#004085",
    backgroundColor: "#81D3E4",
    borderColor: "#b8daff",
  },
  // teal
  fallbackColor: {
    // only used for undefined values
    color: "#004085",
    backgroundColor: "#81D3E4",
    borderColor: "#b8daff",
  },
  // purple
  lowSeverityColor: {
    color: "#004085",
    backgroundColor: "#BB6BD9",
    borderColor: "#b8daff",
  },
  inactiveColor: {
    color: "#1b1e21",
    backgroundColor: "#eaeced",
    borderColor: "#c6c8ca",
  },
};

export default severityColors;
