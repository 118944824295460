// import styled from "styled-components";
import { Panel } from "react-bootstrap";

const SystemStatusPanel = {
  SystemTypeSpan: (props) => {
    const MAKE_PORTALS = {
      sma: "https://sunnyportal.com",
      solaredge: "https://monitoring.solaredge.com/solaredge-web/p/login",
      fronius: "https://www.solarweb.com/Account/ExternalLogin",
      fusion: "https://eu5.fusionsolar.huawei.com",
    };
    if (MAKE_PORTALS[props.info.make]) {
      return (
        <a
          href={props.info.supportUrl || MAKE_PORTALS[props.info.make]}
          referrerPolicy="no-referrer"
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      );
    }
    return <span>{props.children}</span>;
  },
  StyledPanel: Panel,
  panelHeadingPercentage: { small: "2%", large: "1%" },
};

const IssuesList = {
  DailyFooter: () => null,
};

const colors = {
  labelTextColor: "#0c4899",
};

const exported = {
  colors: colors,
  SystemStatusPanel: SystemStatusPanel,
  IssuesList: IssuesList,
};

export default exported;
