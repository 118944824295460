import * as React from "react";

// teal from the palette of soltell
const SOLTELL_TEAL = "#7caada";

const style = {
  color: SOLTELL_TEAL,
};

function ResponseString(props) {
  const { responses } = props;
  if (!responses || !responses.length) {
    return null;
  }
  return (
    <ul style={style}>
      {responses.map((res, idx) => (
        <li key={idx}>{res.responseMessage}</li>
      ))}
    </ul>
  );
}

export default ResponseString;
