import * as React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import LoadingAnim from "../LoadingAnim/ThreeDots";

import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
} from "react-bootstrap";

const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

/* unused */
// const Logo = styled.img`
//   max-width: 50%;
// `

function validateNotEmpty(str) {
  return str.length === 0 ? "Enter a value" : "";
}

function FieldGroup({ id, label, help, vstate = null, ...props }) {
  return (
    <FormGroup controlId={id} validationState={vstate}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formControlsName: "",
      error_formControlsName: validateNotEmpty(""),
      formControlsEmail: "",
      error_formControlsEmail: validateNotEmpty(""),
      formControlsComment: "",
      errorsCount: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const errorsCount = Object.keys(this.state).reduce((acc, key) => {
      return (
        acc + (key.substr(0, 6) === "error_" && this.state[key] !== "" ? 1 : 0)
      );
    }, 0);
    this.setState({
      errorsCount,
    });
  }

  handleChange(ev) {
    // const canSubmit = Object.keys(this.state).reduce((acc, key) => {
    //   return acc && (key.substr(0, 6) === 'error_' && this.state[key] === '')
    // }, true)

    const error = validateNotEmpty(ev.target.value);
    let errorsDelta = 0;
    if (this.state[`error_${ev.target.id}`] === "" && error !== "") {
      errorsDelta = 1;
    }
    if (this.state[`error_${ev.target.id}`] !== "" && error === "") {
      errorsDelta = -1;
    }

    this.setState({
      [ev.target.id]: ev.target.value,
      [`error_${ev.target.id}`]: error,
      errorsCount: this.state.errorsCount + errorsDelta,
    });
  }

  handleChangeComment(ev) {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LogoWrapper>
          <h1>{t("Contact")}</h1>
        </LogoWrapper>
        <p>
          {t(
            "Please submit the form below and we will get back to you shortly"
          )}
          <br />
        </p>
        <form onSubmit={this.handleSubmit}>
          <FieldGroup
            id="formControlsName"
            type="text"
            onChange={this.handleChange}
            label={t("Name")}
            placeholder={t("Enter full name")}
            vstate={
              this.state.error_formControlsName === "" ? "success" : "error"
            }
          />
          <FieldGroup
            id="formControlsEmail"
            type="email"
            onChange={this.handleChange}
            label={t("Email address")}
            placeholder={t("Enter email")}
            vstate={
              this.state.error_formControlsEmail === "" ? "success" : "error"
            }
          />
          <FormGroup controlId="formControlsComment">
            <ControlLabel>{t("Comments")}</ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder={t("Write any additional comments")}
              onChange={this.handleChangeComment}
            />
          </FormGroup>
          {
            //button is disabled until a working solution to sending the form will be found
            this.props.isSubmitting ? (
              <LoadingAnim />
            ) : (
              <Button type="submit" disabled={this.state.errorsCount > 0}>
                {t("Submit")}
              </Button>
            )
          }
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default withTranslation()(Form);
