import * as React from "react";
import PropTypes from "prop-types";
import { ListGroupItem } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import severityColors from "../../services/severityColors";

const StyledListGroupItem = styled(ListGroupItem)`
  background: linear-gradient(
    ${(props) => props.dir},
    ${(props) => props.backgroundcolor} ${(props) => props.percentage},
    white ${(props) => props.percentage}
  );
`;

const getSeverityClass = (severity) => {
  switch (severity) {
    case -1:
      return severityColors.inactiveColor;
    case 0:
      return severityColors.successColor;
    case 1:
      return severityColors.primaryColor;
    case 1.5:
      return severityColors.lowSeverityColor;
    case 2:
      return severityColors.warningColor;
    case 2.5:
      return severityColors.commColor;
    case 3:
      return severityColors.dangerColor;
    default:
      return severityColors.fallbackColor;
  }
};

const Issue = (props) => {
  const [windowWidth] = React.useState(window.innerWidth);
  const direction = props.i18n.dir() === "rtl" ? "to left" : "to right";
  const translator = props.messagesTranslator
    ? props.messagesTranslator
    : props.t;
  return (
    <StyledListGroupItem
      backgroundcolor={getSeverityClass(props.severity).backgroundColor}
      percentage={windowWidth < 767 ? "2%" : "1%"}
      dir={direction}
    >
      {translator(props.msg, props.params)}
    </StyledListGroupItem>
  );
};

Issue.propTypes = {
  messagesTranslator: PropTypes.func, // message translation function from auxiliary module
  msg: PropTypes.string, // raw message
  severity: PropTypes.number, // issue severity (0~3)
  params: PropTypes.object, // parameters to be injected into the message
};
Issue.defaultProps = {
  messagesTranslator: null,
  msg: "NA",
  severity: 85,
  params: {},
};

export default withTranslation()(Issue);
