import AllowedValue from "./AllowedValue";

export default class Setting {
  // id for local storage
  #id;
  // name for rendering
  #name;
  // hint to the type of component to render, string and not a component to ensure localization/styling
  #type;
  // validation callback for setter
  #mutatorCallback;
  // actual value
  #value;
  // optional list of allowed values, for rendering
  #allowedValues;

  constructor(id, name, type, mutatorCallback, allowedValues) {
    this.#id = id;
    this.#name = name;
    this.#type = type;
    this.#mutatorCallback = mutatorCallback;
    this.value = JSON.parse(window.localStorage.getItem(id));
    if (
      Array.isArray(allowedValues) &&
      allowedValues.length &&
      allowedValues.every((e) => e instanceof AllowedValue)
    ) {
      this.#allowedValues = [...allowedValues];
    } else {
      this.#allowedValues = null;
    }
  }

  get id() {
    return this.#id;
  }

  get name() {
    return this.#name;
  }

  get type() {
    return this.#type;
  }

  get value() {
    return this.#value;
  }

  set value(newValue) {
    const value = this.#mutatorCallback(newValue);
    window.localStorage.setItem(this.#id, JSON.stringify(value));
    this.#value = value;
  }

  get allowedValues() {
    return Array.isArray(this.#allowedValues)
      ? this.#allowedValues.map((v) => v.copy())
      : null;
  }
}
