import styled from "styled-components";
import { Grid } from "react-bootstrap";

export const StatsContainer = styled(Grid)`
  margin-bottom: 2em;
`;

export const SectionTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const StatTitle = styled.div`
  display: inline-block;
  margin-bottom: 5px;
`;

export const BarContainer = styled.div`
  vertical-align: top;
  margin-bottom: 10px;
`;
export const MeasuresContainer = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;
