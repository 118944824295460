import styled from "styled-components";
import { Panel } from "react-bootstrap";

const SystemStatusPanel = {
  SystemTypeSpan: (props) => {
    return <span>{props.children}</span>;
  },
  StyledPanel: styled(Panel)`
    border-color: ${(props) => props.border};
    border-style: ${(props) =>
      props.direction === "left"
        ? "solid solid solid none"
        : "solid none solid solid"};
  `,
  panelHeadingPercentage: { small: "4%", large: "1.2%" },
};

const IssuesList = {
  DailyFooter: () => null,
};

const colors = {
  labelTextColor: "#006990",
};

const exported = {
  colors: colors,
  SystemStatusPanel: SystemStatusPanel,
  IssuesList: IssuesList,
};

export default exported;
