import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DoubleBar from "./DoubleBar";
import { withTranslation } from "react-i18next";

import {
  Grid,
  // Row,
  // Col,
} from "react-bootstrap";
import ReactGA from "react-ga";

const Container = styled.div`
  direction: ltr;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
// const SRow = styled(Row)`
//   margin-top: 15px;
// `
const Year = styled.span`
  font-size: 2em;
  margin-right 0.8em;
`;
const BarWrap = styled.div`
  flex: 1;
`;

class SystemAnnualStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.event({
        category: "User",
        action: "Entered annual tab",
      });
      const url = `/{org-id}/${this.props.sysInfo.id}/annual`;
      ReactGA.pageview(url);
    }
  }

  render() {
    const t = this.props.t;

    // const max = props.status.reduce((a, i) => Math.max(a, i.total), 0)
    if (!this.props.sysStatus || this.props.sysStatus.length === 0) {
      return <div>{t("No status data found")}</div>;
    }

    const sData = this.props.sysStatus.slice().sort((a, b) => b.year - a.year);

    return (
      <Container>
        <Grid>
          {sData.map((data) => {
            if (isNaN(data.total)) {
              return null;
            }
            return (
              <Row key={data.year}>
                <Year>{data.year}</Year>
                <BarWrap>
                  <DoubleBar val={data.intact} total={data.total} units="kWh" />
                </BarWrap>
              </Row>
            );
          })}
        </Grid>
      </Container>
    );
  }
}
SystemAnnualStatus.propTypes = {
  sysStatus: PropTypes.array, // status data to display
  sysInfo: PropTypes.object, // static system info
};
SystemAnnualStatus.defaultProps = {
  sysStatus: [],
  sysInfo: null,
};

export default withTranslation()(SystemAnnualStatus);
