import * as React from "react";
import styled, { keyframes } from "styled-components";

import labelStyles from "../../styles/index";
const { colors } = labelStyles;

const DOT_SIZE = "2.5em";
const LENGTH = "1.2";

const load7 = keyframes`
  0%,
  60%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const Dots = styled.div`
  border-radius: 50%;
  width: ${DOT_SIZE};
  height: ${DOT_SIZE};
  animation-fill-mode: both;
  animation: ${load7} ${LENGTH}s infinite ease-in-out;
  color: ${colors.labelTextColor};
  font-size: 10px;
  position: relative;
  margin: 0 auto;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -${LENGTH / 10}s;

  &:after,
  &:before {
    border-radius: 50%;
    width: ${DOT_SIZE};
    height: ${DOT_SIZE};
    animation-fill-mode: both;
    animation: ${load7} ${LENGTH}s infinite ease-in-out;
    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    animation-delay: -${LENGTH / 5}s;
  }

  &:after {
    left: 3.5em;
  }
`;

const LoadingAnim = (props) => {
  return <Dots {...props} />;
};

export default LoadingAnim;
