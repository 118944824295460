import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as ServiceWorkerUtils from "./registerServiceWorker";

import ReactGA from "react-ga";
import "./index.css";
import "./rtl.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

if (process.env.REACT_APP_ENV === "production") {
  ReactGA.initialize("UA-104408934-1");
} else {
  const env = process.env.REACT_APP_ENV;
  console.log(`No GA on ${env ? env : "local"}`);
}

window.addEventListener("beforeinstallprompt", function (e) {
  // beforeinstallprompt Event fired

  // e.userChoice will return a Promise.
  // For more details read: https://developers.google.com/web/fundamentals/getting-started/primers/promises
  e.userChoice.then(function (choiceResult) {
    if (choiceResult.outcome === "dismissed") {
      ReactGA.event({
        category: "User",
        action: "Cancelled home screen install",
      });
    } else {
      ReactGA.event({
        category: "User",
        action: "Added to home screen",
      });
    }
  });
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );
ServiceWorkerUtils.register();
