import * as React from "react";
import { withTranslation } from "react-i18next";
import { MdFileDownload } from "react-icons/md";
import { stringify } from "csv-stringify/lib/sync";

const COLUMNS = [
  { key: "name", header: "name" },
  { key: "month", header: "month" },
  { key: "year", header: "year" },
  { key: "rating", header: "average regional rating" },
  { key: "epi", header: "intactness" },
  { key: "expect", header: "expected yield" },
  { key: "expratio", header: "output vs expected ratio" },
  { key: "cumyear", header: "cumulative annual" },
  { key: "yield", header: "yield" },
  { key: "normalizedYield", header: "normalized monthly yield" },
  { key: "preyield", header: "parallel month last year" },
  { key: "uptime", header: "uptime" },
  { key: "exp_uptime", header: "expected uptime" },
  { key: "exp_uptime_ratio", header: "expected uptime ratio" },
  { key: "messages", header: "monthly messages" },
];

class DownloadCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDownloadUrl: "",
    };
  }

  stringifyAsCsv = (data) => {
    return stringify(data, { header: true, columns: COLUMNS });
  };

  download = (ev) => {
    ev.preventDefault();
    try {
      const output = this.stringifyAsCsv(this.props.prepareDataForDownload());
      const blob = new Blob([output]);
      const fileDownloadUrl = URL.createObjectURL(blob);
      this.setState({ fileDownloadUrl }, () => {
        this.doFileDownload.click();
        URL.revokeObjectURL(fileDownloadUrl);
        this.setState({ fileDownloadUrl: "" });
      });
    } catch (err) {
      console.error(err.message);
      this.setState({ fileDownloadUrl: "" });
    }
  };

  render() {
    return (
      <div>
        <MdFileDownload style={{ cursor: "pointer" }} onClick={this.download} />
        <a
          className="hidden"
          download={this.props.downloadHint}
          href={this.state.fileDownloadUrl}
          ref={(e) => (this.doFileDownload = e)}
        >
          download
        </a>
      </div>
    );
  }
}

export default withTranslation()(DownloadCsv);
