import styled from "styled-components";
import { Panel } from "react-bootstrap";

const ServicesLink = styled.a`
  cursor: pointer;

  &:before {
    content: "»";
    margin: 0 0.4em;
    display: inline-block;
  }
`;

const SystemStatusPanel = {
  SystemTypeSpan: (props) => {
    return <span>{props.children}</span>;
  },
  StyledPanel: Panel,
  panelHeadingPercentage: { small: "2%", large: "1%" },
};

const IssuesList = {
  DailyFooter: (props) => {
    return props.isSubscribed ? (
      props.hasIssues && (
        <ServicesLink
          key="index"
          href={process.env.REACT_APP_LABEL_SERVICES_INDEX_URL}
          target="_blank"
        >
          {props.goToServiceString}
        </ServicesLink>
      )
    ) : (
      <ServicesLink
        key="index"
        href={process.env.REACT_APP_LABEL_ADD_SYSTEM_URL}
        target="_blank"
      >
        {props.subscribeString}
      </ServicesLink>
    );
  },
};

const colors = {
  labelTextColor: "#a4dbf7",
};

const exported = {
  colors: colors,
  SystemStatusPanel: SystemStatusPanel,
  IssuesList: IssuesList,
};

export default exported;
