import * as React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";

import LoadingAnim from "../LoadingAnim/ThreeDots";
import Calendar from "../Calendar";

const DaySwitcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 20px;
`;
const DaysArrow = styled.span`
  ${(props) =>
    props.isDisabled
      ? ` margin-left: 10px;
    margin-right: 10px;
    cursor: not-allowed;
    color: #969696;
  `
      : ` margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    color: #000;
  `}
`;
const DayName = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  color: #000;
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
`;

const LoadingDots = styled(LoadingAnim)`
  margin-bottom: 2em;
`;

class CalendarModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      nextDataDateString: props.currentDateString,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentDateString !== this.props.currentDateString) {
      this.setState({ nextDataDateString: this.props.currentDateString });
    }
  }

  handleShowModal = () => {
    if (
      this._isMounted &&
      this.state.nextDataDateString === this.props.currentDateString
    ) {
      this.setState({ isVisible: true });
    }
  };

  handleHideModal = () => {
    if (this._isMounted) {
      this.setState({ isVisible: false });
    }
  };

  handleNewDate = (newDate, disabled = false) => {
    if (this._isMounted && !disabled) {
      const { loadDailyForSystem, sysId, currentDateString, dateFormat } =
        this.props;

      const newDateMmnt = moment(newDate);
      const nextDataDateString = newDateMmnt.format(dateFormat);

      if (nextDataDateString !== currentDateString) {
        this.setState({ nextDataDateString, isVisible: false }, () =>
          loadDailyForSystem(sysId, newDateMmnt, disabled)
        );
        return;
      }

      this.handleHideModal();
    }
  };

  renderDayName = (isLoading) => {
    const { currentDateString } = this.props;
    const handleClick = isLoading ? () => {} : this.handleShowModal;

    return (
      <DayName pointer={!isLoading} onClick={handleClick}>
        {currentDateString}
        {isLoading ? <LoadingDots /> : null}
      </DayName>
    );
  };

  render() {
    const { t, i18n, currentDateString, dateFormat } = this.props;

    const { isVisible, nextDataDateString } = this.state;

    const direction = i18n.dir();
    const date = moment(currentDateString, dateFormat);
    const maxDate = moment();
    const disabledNextDay = maxDate.isSame(date, "days");
    const disabledPrevDay = false;
    const isLoading = currentDateString !== nextDataDateString;

    // set first day of week and weekends
    const calendarType = direction === "rtl" ? "Hebrew" : "ISO 8601";

    return (
      <>
        <DaySwitcher>
          <DaysArrow
            isDisabled={disabledPrevDay}
            onClick={() =>
              this.handleNewDate(
                moment(date).subtract(1, "day"),
                disabledPrevDay
              )
            }
          >
            {"<"}
          </DaysArrow>
          {this.renderDayName(isLoading)}
          <DaysArrow
            isDisabled={disabledNextDay}
            onClick={() =>
              this.handleNewDate(moment(date).add(1, "day"), disabledNextDay)
            }
          >
            {">"}
          </DaysArrow>
        </DaySwitcher>
        <Modal show={isVisible} onHide={this.handleHideModal} bsSize="small">
          <Modal.Body>
            <Calendar
              shouldRender={isVisible}
              maxDate={maxDate.toDate()}
              value={date.toDate()}
              onChange={(newDate) => this.handleNewDate(newDate)}
              calendarType={calendarType}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleHideModal}>{t("Cancel")}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(CalendarModal);
