import * as React from "react";
import PropTypes from "prop-types";
import firebase from "../../firebase";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { withRouter } from "react-router";

import { Navbar, Nav, NavDropdown, MenuItem } from "react-bootstrap";

const MenuIcon = styled.i`
  vertical-align: middle;
  margin-right: 8px;
  .rtl & {
    margin-left: 8px;
    margin-right: 0;
  }
`;

/* never used */
// const MaintenanceIcon = styled.img`
//   vertical-align: middle;
//   width: 24px;
//   height: 24px;
//   margin-right: 8px;
//   .rtl & {
//     margin-left: 8px;
//     margin-right: 0;
//   }
// `

const FlexNavbar = styled(Navbar)`
  & {
    background-color: white;
  }
  & .container {
    display: flex;
    _flex-direction: row-reverse;
    direction: ltr;
  }
`;
const StyledDropdown = styled(NavDropdown)`
  .navbar-nav &.open .dropdown-menu {
    position: absolute;
    background: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  .rtl & > ul > li {
    text-align: right;
  }
`;
const FlexNavbarHeader = styled(Navbar.Header)`
  flex: 1;
`;

const Header = (props) => {
  const onLogoutClick = (ev) => {
    ev.preventDefault();
    firebase.auth().signOut();
    window.location.reload();
  };

  return (
    <FlexNavbar fixedTop={true} inverse={false} collapseOnSelect={true}>
      <FlexNavbarHeader>
        <Navbar.Brand>
          <Link to="/" style={{ padding: "10px 13px 6px" }}>
            <img
              src={process.env.REACT_APP_LABEL_LOGO_ICON}
              alt="header icon"
              style={{ maxHeight: "100%" }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Brand>{props.t(props.text)}</Navbar.Brand>
      </FlexNavbarHeader>
      <Nav>
        <StyledDropdown
          eventKey={3}
          title={<span className="material-icons">menu</span>}
          id="basic-nav-dropdown"
          style={{ direction: props.i18n.dir() }}
          pullRight={true}
          dir={props.i18n.dir()}
          noCaret
        >
          <MenuItem header={true}>
            <MenuIcon className="material-icons">account_circle</MenuIcon>
            {props.t("Name")}: {props.userName} | {props.t("Organization")}:{" "}
            {props.orgName}
          </MenuItem>

          <MenuItem divider={true} />
          <MenuItem
            eventKey={3.1}
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/");
            }}
          >
            <MenuIcon className="material-icons">home</MenuIcon>
            {props.t("Home")}
          </MenuItem>
          <MenuItem
            eventKey={3.2}
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/about");
            }}
          >
            <MenuIcon className="material-icons">info</MenuIcon>
            {props.t("About")}
          </MenuItem>
          <MenuItem
            eventKey={3.4}
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/settings");
            }}
          >
            <MenuIcon className="material-icons">settings</MenuIcon>
            {props.t("Settings")}
          </MenuItem>
          <MenuItem
            eventKey={3.3}
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/contact");
            }}
          >
            <MenuIcon className="material-icons">message</MenuIcon>
            {props.t("Contact")}
          </MenuItem>
          {process.env.REACT_APP_LABEL_TOS_URL && (
            <MenuItem
              href={process.env.REACT_APP_LABEL_TOS_URL}
              target="_blank"
            >
              <MenuIcon className="material-icons">playlist_add_check</MenuIcon>
              {props.t("Terms of Use")}
            </MenuItem>
          )}
          {props.orgIsEnabled &&
            process.env.REACT_APP_LABEL_SERVICES_INDEX_URL !== "-" && (
              <MenuItem
                href={process.env.REACT_APP_LABEL_SERVICES_INDEX_URL}
                target="_blank"
              >
                <MenuIcon className="material-icons">build</MenuIcon>
                {props.t("Maintenance")}
              </MenuItem>
            )}
          <MenuItem divider={true} />
          <MenuItem onClick={onLogoutClick}>
            <MenuIcon className="material-icons">power_settings_new</MenuIcon>
            {props.t("Sign Out")}
          </MenuItem>
        </StyledDropdown>
      </Nav>
    </FlexNavbar>
  );
};

Header.propTypes = {
  text: PropTypes.string,
  userName: PropTypes.string,
  orgName: PropTypes.string,
};
Header.defaultProps = {
  text: "Page",
  userName: "NA",
  orgName: "NA",
};

export default withRouter(withTranslation()(Header));
