import * as React from "react";
import PropTypes from "prop-types";
import Layout from "../components/DefaultLayout";
import Header from "../components/Header";
import { Dropdown, MenuItem } from "react-bootstrap";
import { IoCaretDown } from "react-icons/io5";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Section = styled.div`
  margin-top: 1em;
  width: 100%;
`;

const Title = styled.div`
  font-size: ${(props) => (props.large ? "150%" : "100%")};
  font-weight: bold;
  ${(props) => (props.marginBottom ? "margin-bottom: 0.2em;" : "")}
`;

const FullWidthDropdown = styled(Dropdown)`
  width: 100%;
`;

const FullWidthMenu = styled(Dropdown.Menu)`
  width: 100%;
  text-align: start;
`;

const FlexDropdownToggle = styled(Dropdown.Toggle)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledDropdownButton = (props) => {
  const { children, id, title } = props;
  return (
    <FullWidthDropdown id={id}>
      <FlexDropdownToggle bsRole="toggle" bsSize="large" noCaret>
        <span>{title}</span>
        <IoCaretDown />
      </FlexDropdownToggle>
      <FullWidthMenu bsRole="menu">{children}</FullWidthMenu>
    </FullWidthDropdown>
  );
};

const LANG_DICT = {
  en: "English",
  he: "Hebrew",
  de: "German",
  es: "Spanish",
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview("/settings");
    }
  }

  renderHeader() {
    return (
      <Header
        text="Settings"
        userName={this.props.userExtra.name}
        orgName={this.props.orgData ? this.props.orgData.name : "NA"}
        orgIsEnabled={this.props.orgData ? this.props.orgData.isEnabled : false}
      />
    );
  }

  handlePrefChange = (idx, value) => {
    this.props.updatePreferences(idx, value);
  };

  // render preference based on type
  Preference = (props) => {
    switch (props.preference.type) {
      case "select":
        return this.selectPreference(props);
      default:
        return null;
    }
  };

  // 'select' type preference
  selectPreference = ({ t, preference, prefIdx }) => {
    const { name, value, allowedValues } = preference;

    const selectedOptionIdx = allowedValues.findIndex(
      (allowed) => value === allowed.value
    );

    // sanity check to avoid crashes
    if (selectedOptionIdx < 0) {
      return null;
    }

    return (
      <Section>
        <Title marginBottom>{t(name)}</Title>
        <StyledDropdownButton
          id={name}
          title={t(allowedValues[selectedOptionIdx].name)}
        >
          {allowedValues.map((allowed, idx) => (
            <MenuItem
              key={allowed.name}
              eventKey={allowed.value}
              active={idx === selectedOptionIdx}
              onSelect={(evKey) => this.handlePrefChange(prefIdx, evKey)}
            >
              {t(allowed.name)}
            </MenuItem>
          ))}
        </StyledDropdownButton>
      </Section>
    );
  };

  renderContent() {
    const { t, preferences } = this.props;

    return (
      <Wrapper>
        <Title large>{t("Settings")}</Title>

        <Section>
          <Title marginBottom>{t("Language")}</Title>
          <StyledDropdownButton
            id="language-selection"
            title={t(LANG_DICT[this.props.i18n.language])}
          >
            {Object.entries(LANG_DICT).map(([langCode, langName]) => (
              <MenuItem
                key={langCode}
                eventKey={langCode}
                active={this.props.i18n.language === langCode}
                onSelect={(evKey) => this.props.i18n.changeLanguage(evKey)}
              >
                {t(langName)}
              </MenuItem>
            ))}
          </StyledDropdownButton>
        </Section>

        {preferences.map((pref, idx) => (
          <this.Preference key={idx} prefIdx={idx} preference={pref} t={t} />
        ))}
      </Wrapper>
    );
  }

  render() {
    return <Layout header={this.renderHeader()} body={this.renderContent()} />;
  }
}

Page.propTypes = {
  userData: PropTypes.object,
  userExtra: PropTypes.object,
  orgData: PropTypes.object,
};

export default withTranslation()(Page);
