import * as React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Form from "./Form";
import SubmitSuccess from "./SubmitSuccess";
import { Modal } from "react-bootstrap";

class NewUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
    };

    this.renderContent = this.renderContent.bind(this);
    this.closeModals = this.closeModals.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(nextProps) {
    if (this.props.isSubmitting && !nextProps.isSubmitting) {
      if (nextProps.submitError) {
        this.setState({
          showError: true,
        });
      }
    }
  }

  closeModals() {
    this.setState({
      showError: false,
    });
  }

  renderContent() {
    if (this.props.isSubmitSuccess) {
      return <SubmitSuccess />;
    }
    // if (this.props.isSubmitting) {
    //   return <LoadingAnim/>
    // }

    return (
      <div>
        <Form
          onSubmit={this.props.onSubmit}
          error={this.props.submitError}
          isSubmitting={this.props.isSubmitting}
        />
        <Modal show={this.state.showError} onHide={this.closeModals}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.t("Error")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.t("There was an error, please try again")}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

NewUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitSuccess: PropTypes.bool.isRequired,
  submitError: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation()(NewUserForm);
