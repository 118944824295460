import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrap = styled.div`
  display: inline-block;
  margin: 0 0.5em;
`;
const Inner = styled.div`
  display: inline-block;
`;
const Units = styled.span`
  font-size: 50%;
  margin-left: -0.5em;
`;
const Plus = styled.span`
  color: green;
  font-size: 1.5em;
`;
const Minus = styled.span`
  color: red;
  font-size: 1.5em;
`;

const SingleMeasure = (props) => {
  const value = props.value ? +props.value : "";
  const formattedVal =
    value !== ""
      ? Math.abs(value) > 1000
        ? value.toFixed(0)
        : value.toFixed(0)
      : "";
  return (
    <Wrap dir="ltr">
      <Inner>
        {value > 0 ? (
          <Plus>
            {formattedVal} <Units>{props.units}</Units>
          </Plus>
        ) : (
          <Minus>
            {formattedVal} <Units>{props.units}</Units>
          </Minus>
        )}
      </Inner>
    </Wrap>
  );
};

SingleMeasure.propTypes = {
  value: PropTypes.string,
  units: PropTypes.string,
};
SingleMeasure.defaultProps = {
  value: "37",
  units: "m2",
};

export default SingleMeasure;
