import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import DashboardPage from "./pages/Dashboard";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import SettingsPage from "./pages/Settings";
// import NotFoundPage from './pages/NotFound'
import { loadOrgData, loadSupportUrls } from "./services/data";

import preferences from "./Preferences";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: null,
      orgLoading: false,
      orgError: null,
      parentSystemIds: [],
      preferences: preferences,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.phoneNumber) {
      this.loadData();
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.user && this.props.user.phoneNumber) {
      this.loadData();
    }
  }

  loadData = () => {
    // we have user, need to load organisation.
    this.setState({
      orgLoading: true,
    });

    this.props.user
      .getIdToken(false)
      .then((token) => {
        return loadOrgData(token).then((orgData) => {
          return Promise.all([
            orgData,
            loadSupportUrls(
              token,
              orgData.systems.map((sys) => sys.id)
            ),
          ]).then(([orgData, supportUrls]) => {
            orgData.systems.forEach((sys) => {
              sys.supportUrl = supportUrls[sys.id] || "";
            });
            return orgData;
          });
        });
      })
      .then((orgData) => {
        // manage parent systems and non-parent systems flags
        let parentSystemIds = this.state.parentSystemIds;
        const subsystems = orgData.systems.filter((sys) => sys.parent_id);
        subsystems.forEach((sys) => {
          if (!parentSystemIds.includes(sys.parent_id))
            parentSystemIds.push(sys.parent_id);
        });
        orgData.systems.forEach((system) => {
          if (parentSystemIds.includes(system.id)) {
            system.isParentSys = true;
          } else {
            system.isParentSys = false;
          }
        });

        // calculate org sizes power system-count wise
        orgData.sysnum = orgData.systems.length - subsystems.length;
        orgData.syspower = orgData.systems.reduce((sum, sys) => {
          return sum + (!sys.parent_id ? sys.power || 0 : 0);
        }, 0);

        this.setState({
          orgData: orgData,
          orgLoading: false,
          parentSystemIds,
        });
      })
      .catch((err) => {
        console.warn("Error loading org data", err);
        this.setState({
          orgData: null,
          orgLoading: false,
          orgError: err,
          parentSystemIds: [],
        });
      });
  };

  updatePreferences = (prefIdx, newValue) => {
    this.setState((state) => {
      const preferences = [...state.preferences];
      preferences[prefIdx].value = newValue;
      return { preferences };
    });
  };

  render() {
    const { user, userExtra } = this.props;
    const { orgData, parentSystemIds, orgLoading } = this.state;
    // for preferences consumers, to reduce array lookups lower down, create
    // an object of references to the backing array
    const preferencesAsObject = this.state.preferences.reduce((acc, pref) => {
      acc[pref.id] = pref;
      return acc;
    }, {});
    return (
      <main style={{ height: "100%" }}>
        {orgLoading || (
          <Router>
            <Switch>
              <Route
                exact={true}
                path="/"
                render={(props) => (
                  <DashboardPage
                    {...props}
                    userData={user}
                    userExtra={userExtra}
                    orgData={orgData}
                    parentSystemIds={parentSystemIds}
                    isFetching={this.state.orgLoading}
                    preferences={preferencesAsObject}
                  />
                )}
              />
              <Route
                path="/about"
                render={(props) => (
                  <AboutPage
                    {...props}
                    userData={user}
                    userExtra={userExtra}
                    orgData={orgData}
                  />
                )}
              />
              <Route
                path="/settings"
                render={(props) => (
                  <SettingsPage
                    {...props}
                    userData={user}
                    userExtra={userExtra}
                    orgData={orgData}
                    preferences={this.state.preferences}
                    updatePreferences={this.updatePreferences}
                  />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <ContactPage
                    {...props}
                    userData={user}
                    userExtra={userExtra}
                    orgData={orgData}
                  />
                )}
              />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </Router>
        )}
      </main>
    );
  }
}

export default Main;
