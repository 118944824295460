import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 10px;
  background-color: ${(props) => (props.isNan ? "#aaa" : "red")};
  position: relative;
  border-radius: 2em;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 10;
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: green;
  height: 100%;
  border-radius: 2em 0 0 2em;
  width: ${(props) => (props.isNan ? "0%" : props.pct * 100 + "%")};
`;

const SimpleBar = (props) => {
  const pct = props.part / props.max;
  const isNan = Number.isNaN(pct);

  return (
    <Wrap isNan={isNan}>
      <Inner pct={pct} isNan={isNan} />
    </Wrap>
  );
};

SimpleBar.propTypes = {
  max: PropTypes.number,
  part: PropTypes.number,
};
SimpleBar.defaultProps = {
  max: 100,
  part: 37,
};

export default SimpleBar;
