import Privileges from "./Privileges";

const userRoles = {
  admin: {
    name: "admin",
    appRole: "operator",
    privileges: {
      [Privileges.postToMlog]: true,
    },
    toFilter: [],
  },

  client: {
    name: "client",
    appRole: "operator",
    privileges: {
      [Privileges.postToMlog]: true,
    },
    toFilter: [],
  },

  demo: {
    name: "demo",
    appRole: "viewer",
    privileges: {},
    toFilter: [],
  },

  whitelabel: {
    name: "whitelabel",
    appRole: "viewer",
    privileges: {},
    toFilter: [
      // TODO: should be returned after a more granulated filter is enabled
      "f-vgridshift",
      // 'if-inv-n',
      // 'if-invdeg-n',
      // 'ef-message',
    ],
  },

  viewer: {
    name: "viewer",
    appRole: "viewer",
    privileges: {},
    toFilter: [
      // TODO: should be returned after a more granulated filter is enabled
      "f-vgridshift",
      // 'if-inv-n',
      // 'if-invdeg-n',
      // 'ef-message',
    ],
  },

  disabled: {
    name: "disabled",
    appRole: "viewer",
    privileges: {},
    toFilter: ["*"], // wildcard, means filter everything
  },
};

export default userRoles;
