import * as React from "react";
import PropTypes from "prop-types";
import Layout from "../components/DefaultLayout";
import Header from "../components/Header";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";

import labelStyles from "../styles/index";
const { colors } = labelStyles;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  color: ${colors.labelTextColor};
  font-size: 300%;
  font-weight: bold;
  text-align: center;
`;
const Logo = styled.img`
  max-width: 50vmin;
  margin: 1em;
`;

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview("/about");
    }
  }

  renderHeader() {
    return (
      <Header
        text="About"
        userName={this.props.userExtra.name}
        orgName={this.props.orgData ? this.props.orgData.name : "NA"}
        orgIsEnabled={this.props.orgData ? this.props.orgData.isEnabled : false}
      />
    );
  }

  renderContent() {
    const t = this.props.t;
    return (
      <Wrapper>
        <Logo src={process.env.REACT_APP_LABEL_LOGO} />
        <Title>
          {this.props.i18n.dir() === "rtl"
            ? `${t("App")} ${process.env.REACT_APP_LABEL_TITLE}`
            : `${process.env.REACT_APP_LABEL_TITLE} ${t("App")}`}
        </Title>
        <span>V.{process.env.REACT_APP_VERSION}</span>
        <span>
          {t("Go to")}{" "}
          <a
            href={process.env.REACT_APP_LABEL_WEBSITE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("the Site")}
          </a>
        </span>
      </Wrapper>
    );
  }

  render() {
    return <Layout header={this.renderHeader()} body={this.renderContent()} />;
  }
}

Page.propTypes = {
  userData: PropTypes.object,
  userExtra: PropTypes.object,
  orgData: PropTypes.object,
};

export default withTranslation()(Page);
