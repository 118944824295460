import * as React from "react";
import styled from "styled-components";
import ThreeDots from "../components/LoadingAnim/ThreeDots";

import labelStyles from "../styles/index";
const { colors } = labelStyles;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  color: ${colors.labelTextColor};
  font-size: 150%;
  font-weight: bold;
  max-width: 43vmin;
  margin: 1em;
  text-align: center;
  direction: ltr;
`;
const Logo = styled.img`
  max-width: 50vmin;
  margin: 1em;
`;

const Splash = (props) => {
  const env =
    process.env.REACT_APP_ENV !== "production" ? process.env.REACT_APP_ENV : "";
  return (
    <Wrapper>
      <Logo src={process.env.REACT_APP_LABEL_LOGO} />
      <Title>{process.env.REACT_APP_LABEL_SLOGAN}</Title>
      <br />
      <Title>{env}</Title>
      <ThreeDots />
    </Wrapper>
  );
};

export default Splash;
