import * as React from "react";
import PropTypes from "prop-types";

/* never used */
// import Header from '../Header';
import styled from "styled-components";
import { Grid, Row } from "react-bootstrap";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 72px;
  overflow: auto;
`;
const HeaderContainer = styled.div`
  width: 100%;
`;

const DefaultLayout = (props) => {
  return (
    <Wrap>
      <HeaderContainer>{props.header}</HeaderContainer>
      <Grid>
        <Row>{props.body}</Row>
      </Grid>
    </Wrap>
  );
};

DefaultLayout.propTypes = {
  header: PropTypes.object,
  body: PropTypes.object,
};
DefaultLayout.defaultProps = {
  header: null,
  body: null,
};

export default DefaultLayout;
