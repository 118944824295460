import * as React from "react";
import PropTypes from "prop-types";
import ContactForm from "../components/ContactForm";
import Layout from "../components/DefaultLayout";
import Header from "../components/Header";
import styled from "styled-components";
import ReactGA from "react-ga";
import sendForm from "../services/contact";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitSuccess: false,
      submitError: null,
      isSubmitting: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview("/contact");
    }
  }

  onSubmit(data) {
    this.setState({
      isSubmitSuccess: false,
      submitError: null,
      isSubmitting: true,
    });

    data.phoneNumber = this.props.userData
      ? this.props.userData.phoneNumber
      : "";

    this.props.userData
      .getIdToken()
      .then((token) => sendForm(data, "contact form", token))
      .then(() => {
        this.setState({
          isSubmitSuccess: true,
          submitError: null,
          isSubmitting: false,
        });
      })
      .catch((err) => {
        this.setState({
          isSubmitSuccess: false,
          submitError: err,
          isSubmitting: false,
        });
      });
  }

  renderHeader() {
    return (
      <Header
        text="Contact"
        userName={this.props.userExtra.name}
        orgName={this.props.orgData ? this.props.orgData.name : "NA"}
        orgIsEnabled={this.props.orgData ? this.props.orgData.isEnabled : false}
      />
    );
  }

  renderContent() {
    return (
      <Wrapper>
        <ContactForm
          onSubmit={this.onSubmit}
          isSubmitSuccess={this.state.isSubmitSuccess}
          submitError={this.state.submitError}
          isSubmitting={this.state.isSubmitting}
          getIdToken={this.props.userData.getIdToken}
        />
      </Wrapper>
    );
  }

  render() {
    return <Layout header={this.renderHeader()} body={this.renderContent()} />;
  }
}

ContactPage.propTypes = {
  userData: PropTypes.object,
  userExtra: PropTypes.object,
  orgData: PropTypes.object,
};

export default ContactPage;
