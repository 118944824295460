import React, { Component } from "react";
import firebase from "../firebase";

class FirebaseUIAuth extends Component {
  constructor(props) {
    super(props);

    this.uiContainerRef = React.createRef();

    this.state = {
      jsLoaded: false,
      cssLoaded: false,
    };
  }

  componentDidMount() {
    // create script tag
    const $js = document.createElement("script");
    $js.onload = () => {
      this.setState({
        jsLoaded: true,
      });
    };
    // create link tag
    const $lnk = document.createElement("link");
    $lnk.type = "text/css";
    $lnk.rel = "stylesheet";
    $lnk.onload = () => {
      this.setState({
        cssLoaded: true,
      });
    };

    const lng = this.props.lang;

    // set relevant asset urls
    if (lng === "he") {
      $js.src =
        "https://www.gstatic.com/firebasejs/ui/2.2.1/firebase-ui-auth__iw.js";
      $lnk.href =
        "https://www.gstatic.com/firebasejs/ui/2.2.1/firebase-ui-auth-rtl.css";
    } else {
      $js.src =
        "https://www.gstatic.com/firebasejs/ui/2.2.1/firebase-ui-auth.js";
      $lnk.href =
        "https://www.gstatic.com/firebasejs/ui/2.2.1/firebase-ui-auth.css";
    }

    // insert into DOM
    const head = document.getElementsByTagName("head")[0];
    head.appendChild($js);
    head.appendChild($lnk);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { jsLoaded, cssLoaded } = this.state;
    // if both assets were loaded
    if (
      (!prevState.jsLoaded || !prevState.cssLoaded) &&
      jsLoaded &&
      cssLoaded
    ) {
      console.log("firebaseui: Both loaded, init");
      this.initUi();
    }
  }

  // initialize the UI widget
  initUi = () => {
    const { lang, ...uiConfig } = this.props;
    const firebaseui = global.firebaseui;
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    this.ui.start(this.uiContainerRef.current, uiConfig);
  };

  componentWillUnmount() {
    this.ui.reset();
  }

  render() {
    const { jsLoaded, cssLoaded } = this.state;
    return (
      <div ref={this.uiContainerRef}>
        {jsLoaded && cssLoaded ? "" : "Loading..."}
      </div>
    );
  }
}

export default FirebaseUIAuth;
