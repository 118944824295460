import * as React from "react";
import { withTranslation } from "react-i18next";
import {
  Row,
  Modal,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";

import messageStatuses from "./messageStatuses";
import ResponseString from "../ResponseString";
import LoadingAnim from "../LoadingAnim/ThreeDots";

function FieldGroup({ id, label, help, children, vstate = null, ...props }) {
  return (
    <FormGroup controlId={id} validationState={vstate}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props}>{children}</FormControl>
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

class MonthlyMessageRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCleanState();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // clean state, used for contructor, and handleExit methods
  getCleanState = (visible = false) => {
    return {
      isVisible: visible,
      isSubmitting: false,
      newMessageStatus: "",
      newMessageResponse: "",
    };
  };

  handleShowModal = () => {
    this.setState((state, props) => {
      if (props.privileges.postToMlog) {
        return { isVisible: true };
      }
    });
  };

  handleHideModal = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState((state) => {
      if (!state.isSubmitting) {
        return { isVisible: false };
      }
    });
  };

  onModalEnter = () => {
    this.setState(() => {
      const newState = { ...this.getCleanState(true) };
      newState.newMessageStatus = this.props.msg.status;
      return { ...newState };
    });
  };

  onFormResponseChange = (ev) => {
    ev.preventDefault();
    this.setState({ newMessageResponse: ev.target.value });
  };

  onFormStatusChange = (ev) => {
    ev.preventDefault();
    this.setState({ newMessageStatus: ev.target.value });
  };

  onFormSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ isSubmitting: true }, async () => {
      const { msg, updateMessageResponse, updateMessageStatus } = this.props;
      const { newMessageResponse, newMessageStatus } = this.state;
      try {
        // check if should update responses
        if (newMessageResponse) {
          await updateMessageResponse(msg, newMessageResponse);
        }
      } catch (err) {
        console.error(err.message);
      }

      try {
        const shouldUpdateStatus =
          messageStatuses[msg.status] && newMessageStatus !== msg.status;
        if (shouldUpdateStatus) {
          await updateMessageStatus(msg, newMessageStatus);
        }
      } catch (err) {
        console.error(err.message);
      }

      if (this._isMounted) {
        this.setState({ ...this.getCleanState() });
      }
    });
  };

  getMessageStyle = () => {
    const status = messageStatuses[this.props.msg.status];
    return {
      cursor: this.props.privileges.postToMlog ? "pointer" : "auto",
      color: status ? status.textColor : "black",
    };
  };

  // captures enter key, to avoid page refresh
  captureEnterKey = (ev) => {
    const ENTER_KEY_CODE = 13;
    if (ev.charCode === ENTER_KEY_CODE) {
      ev.preventDefault();
      if (!this.state.isSubmitting) {
        // mock an event with prevent default
        this.onFormSubmit({ preventDefault: () => {} });
      }
    }
  };

  render() {
    const { t, msg, privileges } = this.props;
    const direction = this.props.i18n.dir();
    const { isVisible, isSubmitting, newMessageStatus, newMessageResponse } =
      this.state;

    return (
      <div>
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <span onClick={this.handleShowModal} style={this.getMessageStyle()}>
            <b>{msg.date.split(".").join("/").substring(0, 5)}</b> -{" "}
            {msg.message}
          </span>
        </Row>
        {
          <Row>
            <ResponseString responses={msg.responses} />
          </Row>
        }

        {privileges.postToMlog && (
          <Modal
            dir={direction}
            show={isVisible}
            onHide={this.handleHideModal}
            onEnter={this.onModalEnter}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Message status")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <FieldGroup
                  id="newMessageStatus"
                  componentClass="select"
                  label={t("Status")}
                  value={newMessageStatus}
                  onChange={this.onFormStatusChange}
                  disabled={!messageStatuses[msg.status] || isSubmitting}
                >
                  {Object.values(messageStatuses)
                    .filter(({ render }) => render)
                    .map(({ value }, idx) => (
                      <option key={idx} value={value}>
                        {t(value)}
                      </option>
                    ))}
                </FieldGroup>
                <FieldGroup
                  id="newMessageResponse"
                  type="text"
                  value={newMessageResponse}
                  onChange={this.onFormResponseChange}
                  label={t("New response")}
                  placeholder={t("Response")}
                  disabled={isSubmitting}
                  onKeyPress={this.captureEnterKey}
                />
              </form>
              <div>
                <div>
                  <b>{msg.message}</b>
                </div>
              </div>
              <div>
                <ResponseString responses={msg.responses} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isSubmitting ? <LoadingAnim /> : null}
              <Button disabled={isSubmitting} onClick={this.handleHideModal}>
                {t("Cancel")}
              </Button>
              <Button
                disabled={isSubmitting}
                onClick={this.onFormSubmit}
                type="submit"
                bsStyle="primary"
              >
                {t("Submit")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(MonthlyMessageRow);
